import {
  Condition,
  ConditionType,
  LogicalGroupCondition,
  LogicalOperator,
  PropertyCondition,
  RulesEngineSchema,
} from '@msslib/models/rules-engine';
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { RulesEngineConditionEditorComponent } from '../condition-editor/condition-editor.component';
import { NgFor } from '@angular/common';

@Component({
  selector: 'lib-rules-engine-logical-group-condition-editor',
  templateUrl: 'logical-group-condition-editor.html',
  styleUrls: ['logical-group-condition-editor.scss'],
  standalone: true,
  imports: [
    NgFor,
    forwardRef(() => RulesEngineConditionEditorComponent),
  ],
})
export class RulesEngineLogicalGroupConditionEditorComponent {
  @Input({ required: true }) public schema: RulesEngineSchema;

  @Input({ required: true }) public condition: Partial<LogicalGroupCondition>;
  @Output() public conditionChange = new EventEmitter<Partial<LogicalGroupCondition>>;

  @Input() public disabled = false;
  @Input() public showErrors: boolean;
  @Input() public testIdSuffix: string;

  public get operatorLabel() {
    switch (this.condition.operator) {
      case LogicalOperator.And: return 'All of';
      case LogicalOperator.Or: return 'Any of';
    }
  }

  public get operatorSubLabel() {
    switch (this.condition.operator) {
      case LogicalOperator.And: return 'AND';
      case LogicalOperator.Or: return 'OR';
    }
  }

  public toggleOperator() {
    if (this.disabled) {
      return;
    }
    this.condition.operator = this.condition.operator === LogicalOperator.And
      ? LogicalOperator.Or
      : LogicalOperator.And;
    this.conditionChange.emit(this.condition);
  }

  public addSubCondition() {
    const operands = this.condition.operands ?? (this.condition.operands = []);
    operands.push({ type: ConditionType.Property, propertyName: '' } as PropertyCondition);
    this.conditionChange.emit(this.condition);
  }

  public changeSubCondition(subCondition: Condition | null, index: number) {
    // If null, child condition has been deleted
    if (subCondition !== null) {
      const operands = this.condition.operands ?? (this.condition.operands = []);
      operands[index] = subCondition;
      this.conditionChange.emit(this.condition);

    } else if (this.condition.operands) {
      this.condition.operands.splice(index, 1);
      this.conditionChange.emit(this.condition);
    }
  }

  public trackByIndex(index: number) {
    return index;
  }
}
