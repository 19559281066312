import { OutcomeResults, YesNoResults } from '@msslib/models/enums';
import { SortOrder } from '@msslib/models/filter-sort';

export interface IIgniteFilterOption {
  lenderName?: any[] | null;
  igniteResultsOrder?: SortOrder;
  outcomeResult?: OutcomeResults;
  propertyResult?: OutcomeResults;
  productsMatched?: YesNoResults;
}

export enum FilterKey {
  LenderName = 'lenderName',
  IgniteResultsOrder = 'igniteResultsOrder',
  OutcomeResult = 'outcomeResult',
  PropertyResult = 'propertyResult',
  ProductsMatched = 'productsMatched',
}
