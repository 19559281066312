<div class="modal-body">
    <div class="case-info p-3 text-center">
      <h4>Linked to Club Hub Case {{s365Data?.reference}}</h4>
      <div>
        <p>You're about to return to the Ignite main menu.</p>
        <p>Would you like to  start a new search or keep your client  details?</p>
      </div>
    </div>
    <div class="d-flex">
      <div class="col-6 d-flex flex-column btn-col p-3">
        <button type="button" (click)="keepDetails()" class="btn btn-outline-2022--secondary w-100 h-100">
          Keep Details
        </button>
      </div>
      <div class="col-6 d-flex flex-column btn-col p-3">
        <button type="button" (click)="newSearch()" class="btn btn-outline-2022--secondary w-100 h-100">
          New Search
        </button>
      </div>
    </div>
  </div>
