import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  TemplateRef,
  computed,
  contentChild,
  forwardRef,
  input,
  model,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { autoUpdate, computePosition, shift, size } from '@floating-ui/dom';
import { Animations } from '@msslib/animations';

const childParentOption = Symbol.for('isChildOption');

export enum SelectGroupMode {
  /** In this mode, the parent of the option group will not be selectable. */
  NoSelectParent,

  /** In this mode, the parent of the option group must be selected before the child options are selectable. */
  ChildRequiresParent,

  /** In this mode, selecting the parent will select all of the children and disable them (locking them selected).
   * Children can be independently selected when the parent is not selected. */
  Cascade,
}

/**
 * Component that presents a dropdown and allows the user to select one or multiple options.
 *
 * Is virtualized, has keyboard support, and is compatible with Angular Forms.
 */
@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  animations: [Animations.openClose({ openDuration: '150ms', closeDuration: '100ms' })],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    ScrollingModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  host: {
    // Allow tab stop on this component if it's not disabled. If the search input is focused, we also disable it which
    // allows shift-tabbing to return to previous element on the page.
    '[tabIndex]': 'disabled() || isSearchFocused() ? -1 : 0',
  },
})
export class SelectComponent implements ControlValueAccessor, OnDestroy {
  protected readonly reference = viewChild.required<ElementRef<HTMLElement>>('reference');
  protected readonly dropdown = viewChild.required<ElementRef<HTMLElement>>('dropdown');
  protected readonly searchInput = viewChild<ElementRef<HTMLInputElement>>('searchInput');
  protected readonly virtualScroller = viewChild<CdkVirtualScrollViewport>(
    'virtualScroller');
  protected readonly optionTemplate = contentChild(TemplateRef<any>);

  /** The list of options to present in the dropdown. */
  public readonly options = input.required<any[] | undefined>();

  /** If true, when options is null, undefined, or an empty array, a loading spinner will be shown in the dropdown. */
  public readonly showLoadingWhenNoOptions = input(false);

  /** Whether to allow selecting multiple options. */
  public readonly multi = input(false);

  /** The selector function that returns the label for an option, or the name of a property to use.
   * Defaults to the option itself for simple types, or the 'label' property for anything else. */
  public readonly labelSelector = input<((option: any) => string) | string>((option: any) =>
    option === null || option === undefined ? '' : typeof option === 'object' ? option['label'] : option);

  /** The selector function that returns the value for an option, or the name of a property to use.
   * Defaults to the option itself for simple types, or the 'value' property for anything else. */
  public readonly valueSelector = input<((option: any) => any) | string>((option: any) =>
    option === null || option === undefined ? '' : typeof option === 'object' ? option['value'] : option);

  /** The selector that returns whether or not an option is enabled, or the name of a property to use.
   * By default, for objects, returns either the `enabled` property if it exists, or the inverse of `disabled` if that
   * exists. If neither exist, or the option is not an object, just returns `true`. */
  public readonly enabledSelector = input<((option: any) => boolean) | string>((option: any) => {
    if (option !== null && option !== undefined && typeof option === 'object') {
      if (typeof option['enabled'] === 'boolean') {
        return option['enabled'];
      } else if (typeof option['disabled'] === 'boolean') {
        return !option['disabled'];
      }
    }
    return true;
  });

  /** If provided, enables grouping of options. This should either be the name of a property on the option to use, or a
   * function which returns the children.
   *
   * Note: using grouping with primitive types (string/number/etc) will not work. They must be objects. */
  public readonly childrenSelector = input<((option: any) => any[]) | string | null>(null);

  /** When grouping the options, how the groups should behave. */
  public readonly groupMode = input(SelectGroupMode.NoSelectParent);

  /** A function that calculates the label to be used for the button. Receives all selected OPTIONS (not values) as a
   * parameter. If `undefined` is returned, uses default logic. */
  public readonly buttonLabelSelector = input<(options: any[]) => string | undefined>();

  /** For multi-selects, whether to show the 'X options selected' badge at the end of the button. */
  public readonly showItemCount = input(true);

  /** The singular form of the name for the options (used to show '1 option selected' in the button).
   * Defaults to 'option'. */
  public readonly singularOptionName = input('option');

  /** The plural form of the name for the options (used to show '2 options selected' in the button).
   * Defaults to the singular form with an 's' appended. */
  public readonly pluralOptionName = input<string>();

  protected readonly pluralOptionNameActual = computed(() =>
    this.pluralOptionName() ?? (`${this.singularOptionName()}s`));

  /** Placeholder to show when no options are selected. */
  public readonly placeholder = input('');

  /** Bootstrap size for the select. */
  public readonly size = input<'sm' | 'md' | 'lg'>('md');

  /** Maximum height of the dropdown in pixels. */
  public readonly maxHeight = input(400);

  /** The height of a single option element when rendered in the HTML. Required to make virtualisation work.
   * If using a custom option template, you may need to change this. Otherwise leave as default. */
  protected readonly optionHeight = input(31);

  /** If true, shows the selected tick mark on single-selects and the checkbox on multi-selects. Default true. */
  protected readonly showCheckboxes = input(true);

  /** Whether to show a search box for filtering the the options. */
  public readonly showSearch = input(false);

  public readonly disabled = model(false);

  /** For single-selects, the value that is selected (null if none is). For multi-selects, the values that are selected.
   * Values are determined by the `valueSelector` function. */
  public readonly selectedValue = model<any>([], { alias: 'value' });

  /** Selected values as an array. May be empty if nothing is selected. Applies to single-selects also. */
  protected readonly selectedValueArray = computed(() => {
    const selectedValue = this.selectedValue();
    if (Array.isArray(selectedValue)) {
      return selectedValue;
    }
    if (selectedValue === null || selectedValue === undefined) {
      return [];
    }
    return [selectedValue];
  });

  /** For multi-selects only, an event that fires when a single value from the select is checked/unchecked. */
  public readonly valueSelected = output<{ value: any; selected: boolean }>();

  /** For single-selects, if true will show a blank option at the top of the list. */
  public readonly showBlankOption = model(false);

  /** For single-selects with showBlankOption = true, the label to use for the blank option. */
  public readonly blankOptionLabel = model('Please select...');

  /** If a testid is provided, will add data-testid attributes to the dropdown button and items.
   * Note that the value selector should return a value that can be toString()ed for the option IDs to be unique. */
  public readonly testId = input('');

  protected readonly isOpen = signal(false);
  protected readonly searchTerm = model('');
  protected readonly isSearchFocused = signal(false);
  protected readonly focusedOptionIndex = signal<number | null>(null);
  private floatingUiCleanup: (() => void) | undefined;

  protected readonly showLoadingSpinner = computed(() =>
    this.showLoadingWhenNoOptions() &&
    (this.options() === null || this.options() === undefined || this.options()?.length === 0));

  /**
   * Gets the options currently visible in the dropdown (e.g. after searching).
   * Computed because this can be expensive if there are thousands of items.
   */
  protected readonly visibleOptions = computed(() => {
    const allOptions = this.options() ?? [];
    const visibleOptions: any[] = [];

    const searchTermLower = this.showSearch() && this.searchTerm()?.length
      ? this.searchTerm().toLowerCase()
      : '';

    for (const option of allOptions) {
      let children = this.getChildren(option);

      // Perform the filtering on the children.
      children = searchTermLower?.length
        ? children.filter(o => this.getLabel(o)?.toString().toLowerCase().includes(searchTermLower))
        : children;

      // Link the parent option on the child.
      children.forEach(c => c[childParentOption] = option);

      // Filter the parent. We show the parent if the filter matches the parent, or if it matches ANY of the children.
      const showParent = children?.length > 0
        || !searchTermLower?.length
        || (this.getLabel(option)?.toString().toLowerCase().includes(searchTermLower) as boolean ?? false);

      // If the parent matched, insert it (along with any of it's matching children) into the visibleOptions array.
      // By pushing the children into the array (rather than having them be in a property on the parent), it means we
      // don't have to do any additional work to get the virtualisation to work properly.
      if (showParent) {
        visibleOptions.push(option, ...children);
      }
    }

    return visibleOptions;
  });

  /** A list of ALL options in the system, flattened to include child options.
   * The order of the options in this array is sorted by parent (top-level) option, then child.
   * E.G. Parent1, Parent1Child1, Parent1Child2, Parent2, Parent3, Parent3Child1, etc.
  */
  private readonly optionsFlattened = computed(() => this.options()?.flatMap(option => {
    const children = this.getChildren(option);
    return [
      { option, optionValue: this.getValue(option), parent: null, children },
      ...children.map(childOption =>
        ({ option: childOption, optionValue: this.getValue(childOption), parent: option, children: null })),
    ];
  }));

  protected readonly optionsContainerHeight = computed(() =>
    // We put a max on it, as when dealing with thousands of items, if this value is too big it appears to break the CSS
    // and it leaks out the bottom of the container for some reason. 100 is chosen arbitrarily, any value that is more
    // than we would ever see but less than a couple thousand would work.
    `${this.optionHeight() * Math.min(this.visibleOptions().length, 100)}px`,
  );

  protected readonly displayLabel = computed(() => {
    if (this.buttonLabelSelector() !== undefined) {
      const selectedOptions = this.selectedValueArray()
        .map(v => this.getOptionFromValue(v));
      const buttonLabel = this.buttonLabelSelector()?.(selectedOptions);
      if (typeof buttonLabel === 'string') {
        return buttonLabel;
      }
    }

    return this.selectedValueArray()
      .slice(0, 20) // prevent allocating large strings if many options are selected
      .map(v => this.getOptionFromValue(v))
      .filter(o => o)
      .map(o => this.getLabel(o))
      .join(', ') ?? '';
  });

  protected readonly selectedItemCountLabel = computed(() => {
    const length = this.selectedValueArray().length ?? 0;
    return `${length} ${length === 1 ? this.singularOptionName() : this.pluralOptionNameActual()} selected`;
  });

  protected readonly selectGroupMode = SelectGroupMode;

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  public ngOnDestroy(): void {
    this.close();
  }

  protected getLabel(option: any) {
    const labelSelector = this.labelSelector();
    return typeof labelSelector === 'function' ? labelSelector(option) : option[labelSelector];
  }

  protected getValue(option: any) {
    const valueSelector = this.valueSelector();
    return typeof valueSelector === 'function' ? valueSelector(option) : option[valueSelector];
  }

  protected getEnabled(option: any) {
    // If this is a child option, then depending on the group mode, it may be disabled regardless of the selector.
    if (this.isChildOption(option)) {
      const isParentSelected = this.isOptionSelected(this.getParentOption(option));
      if (this.groupMode() === SelectGroupMode.ChildRequiresParent && !isParentSelected) {
        return false;
      } else if (this.groupMode() === SelectGroupMode.Cascade && isParentSelected) {
        return false;
      }
    }

    // If no special cases apply because of the grouping, use the enabledSelector.
    const enabledSelector = this.enabledSelector();
    return typeof enabledSelector === 'function' ? enabledSelector(option) : option[enabledSelector];
  }

  protected getChildren(option: any): any[] {
    const childrenSelector = this.childrenSelector();
    switch (typeof childrenSelector) {
      case 'function': return childrenSelector(option) ?? [];
      case 'string': return option[childrenSelector] ?? [];
      default: return [];
    }
  }

  protected open({ focusSearch = true }: { focusSearch?: boolean } = {}): void {
    if (this.isOpen()) {
      return;
    }

    const referenceElement = this.reference().nativeElement;
    const dropdownElement = this.dropdown().nativeElement;

    this.floatingUiCleanup?.();
    if (referenceElement && dropdownElement) {
      this.floatingUiCleanup = autoUpdate(referenceElement, dropdownElement, async () => {
        const { x, y } = await computePosition(referenceElement, dropdownElement, {
          placement: 'bottom-start',
          strategy: 'fixed',
          middleware: [
            shift(),
            size({
              apply: ({ availableHeight }) => {
                Object.assign(dropdownElement.style, {
                  maxHeight: `${Math.max(0, Math.min(availableHeight - 16, this.maxHeight()))}px`,
                });
              },
            }),
          ],
        });

        Object.assign(dropdownElement.style, {
          top: `${y - 1}px`,
          left: `${x}px`,
          width: `${referenceElement.offsetWidth}px` });
      });

      if (focusSearch && this.showSearch()) {
        setTimeout(() => this.searchInput()?.nativeElement.focus());
      }
    }

    this.isOpen.set(true);
  }

  protected close(): void {
    if (this.isOpen) {
      this.floatingUiCleanup?.();
      this.floatingUiCleanup = undefined;
      this.isOpen.set(false);
      this._onTouched?.();
      this.focusedOptionIndex.set(null);
    }
  }

  protected closeIfSingleSelect(): void {
    if (!this.multi()) {
      this.close();
    }
  }

  protected clearSearchTerm(): void {
    this.searchTerm.set('');
    this.searchInput()?.nativeElement.focus();
  }

  protected openButtonClick(): void {
    if (this.disabled()) {
      return;
    }

    if (this.isOpen()) {
      this.close();
    } else {
      // Do as microtask so that the global mouse down handler fires first
      setTimeout(() => this.open());
    }
  }

  // Close the dropdown when clicking outside
  // eslint-disable-next-line spellcheck/spell-checker
  @HostListener('document:mousedown', ['$event.target'])
  public onDocumentMouseDown(target: HTMLElement): void {
    if (this.dropdown().nativeElement !== target && !this.dropdown().nativeElement.contains(target)) {
      this.close();
    }
  }

  /** Gets the option from this value. */
  private getOptionFromValue(optionValue: any) {
    return this.optionsFlattened()?.find(o => o.optionValue === optionValue)?.option;
  }

  protected isOptionSelected(option: any) {
    const value = this.getValue(option);
    return this.selectedValueArray().includes(value);
  }

  protected setOptionSelected(option: any, isSelected: boolean): void {
    const optionValue = this.getValue(option);
    const isCurrentlySelected = this.selectedValueArray().includes(optionValue);

    if (
      this.disabled() ||
      isSelected === isCurrentlySelected ||
      (this.groupMode() === SelectGroupMode.NoSelectParent && this.isParentOption(option))
    ) {
      return;
    }

    if (this.multi()) {
      const selectedValuesArray = this.selectedValueArray();
      this.selectedValue.set(isSelected
        ? this.sortOptionValues([...(selectedValuesArray), optionValue])
        : selectedValuesArray.filter(value => value !== optionValue),
      );
    } else {
      this.selectedValue.update(value => {
        if (value === optionValue && !isSelected) {
          return null;
        } else if (isSelected) {
          return optionValue;
        }
      });
    }

    this._onChange?.(this.selectedValue());

    if (this.multi()) {
      // This only really makes sense for multi-selects. For single selects, can use (valueChange) instead.
      this.valueSelected.emit({ value: optionValue, selected: isSelected });
    }

    // If the selected option was a parent option, then we may need to update the children depending on the mode.
    if (this.isParentOption(option)) {
      const children = this.getChildren(option);
      switch (true) {
        // When selecting/deselecting a parent in Cascading mode, select all children.
        case this.groupMode() === SelectGroupMode.Cascade:
          children.forEach(child => this.setOptionSelected(child, isSelected));
          break;

        // When de-selecting a parent in ChildRequiresParent mode, de-select all children.
        case this.groupMode() === SelectGroupMode.ChildRequiresParent && !isSelected:
          children.forEach(child => this.setOptionSelected(child, false));
          break;
      }
    }
  }

  /** Sorts a set of option values to be equal to the order they were provided in the options array. */
  private sortOptionValues(optionValues: any[]): any[] {
    return optionValues
      .map(optionValue => {
        const index = this.optionsFlattened()?.findIndex(o => o.optionValue === optionValue) ?? Number.MAX_SAFE_INTEGER;
        return { optionValue, index };
      })
      .sort((a, b) => a.index - b.index)
      .map(({ optionValue }) => optionValue);
  }

  private toggleOptionSelected(option: any) {
    const optionValue = this.getValue(option);
    const isCurrentlySelected = this.selectedValueArray().includes(optionValue);
    this.setOptionSelected(option, !isCurrentlySelected);
  }

  protected isChildOption(option: any) {
    return this.childrenSelector() !== null && childParentOption in option;
  }

  protected isParentOption(option: any) {
    return this.childrenSelector() !== null && !(childParentOption in option);
  }

  protected getParentOption(childOption: any) {
    return childOption[childParentOption];
  }

  protected onOptionMouseEnter(index: number) {
    this.focusedOptionIndex.set(index);
  }

  protected onOptionMouseLeave(index: number) {
    // In case the focused signal has already been updated with a MouseEnter, then don't change it
    if (this.focusedOptionIndex() === index) {
      this.focusedOptionIndex.set(null);
    }
  }

  protected trackByOptionValue = (option: any) => this.getValue(option);

  // Keyboard navigation
  @HostListener('keydown', ['$event'])
  public onKeyDown(evt: KeyboardEvent) {
    // May receive events when this component itself is not focused (e.g. when typing in the search box)
    const isComponentFocused = evt.target === this.elementRef.nativeElement;
    const isSearchFocused = evt.target === this.searchInput()?.nativeElement;

    // If the component itself is focused and the user presses space/down, then open the dropdown.
    if (isComponentFocused && ['Space', 'ArrowDown'].includes(evt.code) && !this.isOpen()) {
      this.open({ focusSearch: false });

      // If the component is focused and open, and the user presses esc, close it
    } else if (isComponentFocused && evt.code === 'Escape' && this.isOpen()) {
      this.close();

      // If the user tabs from the input, move to the list options
    } else if (isSearchFocused && evt.code === 'Tab' && !evt.shiftKey && this.visibleOptions().length > 0) {
      this.elementRef.nativeElement.focus();
      this.focusedOptionIndex.set(0);

      // If the user shift-tabs while focused on an option, and searching is enabled, focus on the search input
    } else if (isComponentFocused && evt.shiftKey && evt.code === 'Tab' && this.focusedOptionIndex() !== null) {
      this.searchInput()?.nativeElement.focus();
      this.focusedOptionIndex.set(null);

      // When pressing up/down arrows move up/down
    } else if (isComponentFocused && evt.code === 'ArrowUp') {
      this.focusedOptionIndex.update(i => Math.max((i ?? 0) - 1, 0));
      this.virtualScroller()?.scrollToIndex(this.focusedOptionIndex() ?? 0);
    } else if (isComponentFocused && evt.code === 'ArrowDown') {
      this.focusedOptionIndex.update(i => Math.min((i ?? -1) + 1, this.visibleOptions().length - 1));
      this.virtualScroller()?.scrollToIndex(this.focusedOptionIndex() ?? 0);

      // When pressing space, while focused on a list option, select it (and close if this is a single-select)
    } else if (isComponentFocused && evt.code === 'Space' && this.focusedOptionIndex() !== null) {
      if (this.multi()) {
        this.toggleOptionSelected(this.visibleOptions()[this.focusedOptionIndex() ?? 0]);
      } else {
        this.setOptionSelected(this.visibleOptions()[this.focusedOptionIndex() ?? 0], true);
        this.close();
      }

      // If the user presses escape while on the search input, return focus to the dropdown
    } else if (isSearchFocused && evt.code === 'Escape') {
      this.elementRef.nativeElement.focus();

      // If none of the above, don't preventDefault()
    } else {
      return;
    }

    evt.preventDefault();
  }

  @HostListener('blur')
  public onBlur() {
    // When the component blurs, if the focused element is not in the dropdown, then close the dropdown.
    // Need to do as a microtask, otherwise document.activeElement always points to <body> for some reason.
    setTimeout(() => {
      if (!this.elementRef.nativeElement.contains(document.activeElement)) {
        this.close();
      }
    });
  }

  // ControlValueAccessor interface (for Angular Forms integration)
  private _onChange: ((value: any[]) => void) | undefined;
  private _onTouched: (() => void) | undefined;

  public writeValue(value: any[]): void {
    this.selectedValue.set(value);
  }

  public registerOnChange(fn: (value: any[]) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
}
