import { OutcomeResponse } from 'apps/shared/src/models';

export interface IgniteResponseError {
  statusCode: string | null;
  message: string | null;
}

export interface MinimumRentalNeededAmount {
  period: number;
  amount: number;
  asantoCalculation: string;
}

export interface MaximumLoanAmount {
  period: number;
  amount: number;
}

export class IgniteResponse {
  public affordabilitySearchId: string | null;
  public source: string | null;
  public lenderName: string;
  public lenderLogoUrl?: string;
  public maximumLoanAmount: number | null;
  public maximumLoanAmounts?: MaximumLoanAmount[] | null;
  public minimumRentalNeededAmounts?: MinimumRentalNeededAmount[] | null;
  public maximumAffordableLoanAmount: number | null;
  public maximumAffordableLoanAmountDescription?: string | null;
  public maximumValue: number | null;
  public affordabilityResult: string | null;
  public quoteNumber: string | null;
  public error: IgniteResponseError | null;
  public httpError: string | null;
  public httpErrorMessage: string | null;
  public tooManyApplicants: boolean | null;
  public limitToTwoIncomes?: boolean | null;
  public payrate: number | null;
  public stressRate: number | null;

  // UI only
  public expanded?: boolean;

  public static response(outcome: OutcomeResponse): IgniteResponse {
    return {
      affordabilitySearchId: null,
      source: null,
      lenderName: outcome.overallResult.lender,
      lenderLogoUrl: outcome.overallResult.lenderLogoUrl,
      maximumLoanAmount: null,
      maximumLoanAmounts: null,
      minimumRentalNeededAmounts: null,
      maximumAffordableLoanAmount: null,
      maximumValue: null,
      affordabilityResult: null,
      quoteNumber: null,
      error: null,
      httpError: null,
      httpErrorMessage: null,
      tooManyApplicants: null,
      limitToTwoIncomes: null,
      payrate: null,
      stressRate: null,
      expanded: false,
    };
  }
}
