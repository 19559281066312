import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ClientDetails, CostsToBePaidDetailed,
  EsisEorGenFormDetails,
  EsisEorGenerationRequest,
  EsisSearchDetailsModel,
  GeneratedLendingDocumentType,
  ICustomFee,
  IgnitePlusCallbackDocument,
  IgnitePlusSourcingCallbackRequest,
  LendingTypeCode,
  Loan,
  MortgageType,
  OutcomeViewModel,
  Product,
  PurchaserType,
  RepaymentMethod,
  WhenPayable,
  WhenRefundable,
} from 'apps/shared/src/models';
import { downloadBlob } from '@msslib/helpers/url-helpers';
import { GridStateModel } from '@msslib/models/simple-grid';
import { FormsValidators } from '@msslib/components/forms/validators/forms.validators';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder,
  UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EsisEorDocumentApiSuffix, EsisEorDocumentService } from '../../../services/esis-eor-document.service';
// TODO: Remove CLUBHUB dependency
import {
  ProductsFilterService,
} from 'apps/clubhub/src/app/ignite/services/products-filter.service';
import { FilterOptionsContext } from 'apps/clubhub/src/app/ignite/services/products-filter';
import { AnalyticsService } from '../../../services/analytics.service';
import { Observable, firstValueFrom } from 'rxjs';
import { EsisFormBaseComponent } from '../esis-form-base.component';
import { AuthorizeService, ConfigService, LendingTypeService, ModalService } from '@msslib/services';
import { DocumentPreferencesComponent } from '@msslib/components/document-preferences';
import { FirmDetailsComponent } from '@msslib/components/firm-details/firm-details.component';
import { FeeCalculationScenario, ProductFeeType, feesDisplayNames }
  from '@msslib/constants';
import { v4 as newGuid } from 'uuid';
import { PropertyDetails } from 'apps/clubhub/src/app/ignite/models/products';
import { CaseData, SsoCallBackEventType } from 'apps/clubhub/src/app/ignite/models';
import { CustomProductCalculations, CustomProductFees, ProductCollectionType } from '@msslib/models';
import { OutcomeDetailsCardV2 } from 'apps/clubhub/src/app/ignite/pages/criteria-v2/models';
import { IgniteCommonDataService, IgniteService, ProductsCalculationService,
  ProductsSearchService } from 'apps/clubhub/src/app/ignite/services';
import { ProductsUtils } from 'apps/shared/src/utils/products.utils';
import clamp from 'lodash-es/clamp';
import { roundToNearest, roundToPence } from '@msslib/helpers';
import { EsisAdditionalInformationComponent }
  from '../esis-additional-information/esis-additional-information.component';
import { EsisProductSummaryComponent } from '../esis-product-summary/esis-product-summary.component';
import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

enum EsisModalPage {
  Loading,
  DocumentPreferences,
  Form,
  DocDownload,
}

enum EsisModalTab {
  ProductSummary,
  AdditionalInformation,
}

interface DownloadButtonDefinition {
  name: string;
  description: string;
  unavailableDescription: string;
  docType: GeneratedLendingDocumentType;
  condition?: () => boolean;
}

interface EsisClient {
  forename: string;
  surname: string;
}

interface EsisClientDetails {
  clients: EsisClient[];
  postcode: string;
  referenceNumber: string;
}

interface EsisDetails {
  clientDetails: EsisClientDetails;
}


@Component({
  selector: 'lib-esis-additional-modal',
  styleUrls: ['../esis-tab-shared-style.scss', 'esis-additional-modal.component.scss'],
  templateUrl: 'esis-additional-modal.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    FirmDetailsComponent,
    DocumentPreferencesComponent,
    FormsModule,
    ReactiveFormsModule,
    EsisProductSummaryComponent,
    EsisAdditionalInformationComponent,
    NgFor,
    AsyncPipe,
  ],
})
export class EsisAdditionalModalComponent extends EsisFormBaseComponent implements OnInit {
  @ViewChild('documentPreferencesForm') public documentPreferencesForm: DocumentPreferencesComponent;
  @ViewChild('firmDetailsForm') public firmDetailsForm: FirmDetailsComponent;
  @Input() public endpointSuffix: EsisEorDocumentApiSuffix;
  @Input() public product: Product;
  @Input() public productRank: number;
  @Input() public searchDetails: EsisSearchDetailsModel;
  @Input() public productGridState: GridStateModel;
  @Input() public originalRequest: unknown;
  @Input() public filterContext: FilterOptionsContext<Product>;
  @Input() public customProductCalculations: CustomProductCalculations;
  @Input() public igniteLenders: string[];
  @Input() public propertyDetails: PropertyDetails;
  @Input() public outcomeIds: OutcomeViewModel;
  @Input() public clientDetails: ClientDetails | undefined;
  @Input() public s365Data: CaseData | null;
  @Input() public isBridging: boolean;
  @Input() public isCriteriaV2: boolean;
  @Input() public criteriaV2Contexts: OutcomeDetailsCardV2;
  @Input() public fullDatasource: any[];
  @Input() public isEorOnly: boolean;
  @Input() public customTrueCostPeriod: string;

  public esisFormDetails: EsisEorGenFormDetails;
  public activePage: EsisModalPage = EsisModalPage.Loading;
  public activeTab: EsisModalTab = EsisModalTab.ProductSummary;
  public isGeneratingDocs = false;
  public generatedDocumentId = '';
  public generatedDocumentTypes: GeneratedLendingDocumentType[] = [];
  public isSsoUser$: Observable<boolean | null>;
  public page = EsisModalPage;
  public tab = EsisModalTab;

  private readonly _downloadButtons: DownloadButtonDefinition[] = [
    {
      name: 'ESIS',
      description: 'Download an ESIS for all regulated products including Consumer and Family Buy to Let',
      unavailableDescription: 'Unavailable for this Lender',
      docType: GeneratedLendingDocumentType.Esis,
      condition: () =>
        this.lendingTypeService.currentLendingTypeCode?.toLowerCase() !== LendingTypeCode.Bdg.toLowerCase(),
    },
    {
      name: 'Illustration',
      description: 'Download an Illustration for all non-regulated products',
      unavailableDescription: 'Unavailable for this Lender',
      docType: GeneratedLendingDocumentType.Illustration,
      condition: () => this.lendingTypeService.currentLendingTypeCode === LendingTypeCode.Btl,
    },
    {
      name: 'Evidence of Research',
      description: '',
      unavailableDescription: '',
      docType: GeneratedLendingDocumentType.Eor,
    },
  ];

  public constructor(
    private fb: UntypedFormBuilder,
    private esisEorDocumentService: EsisEorDocumentService,
    private productsFilterService: ProductsFilterService,
    public modalService: ModalService,
    private analyticsService: AnalyticsService,
    private modalElement: ElementRef<HTMLDivElement>,
    private authService: AuthorizeService,
    private lendingTypeService: LendingTypeService,
    private configService: ConfigService,
    private productsCalculationService: ProductsCalculationService,
    private productsSearchService: ProductsSearchService,
    private igniteService: IgniteService,
    private igniteCommonDataService: IgniteCommonDataService,
    private productsUtils: ProductsUtils,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.fetchEsisFormDetails();
    this.createForm();
    this.isSsoUser$ = this.authService.isSsoUser$;
  }

  public get isFormPage() {
    return this.activePage === this.page.Form;
  }

  public get isDownloadPage() {
    return this.activePage === this.page.DocDownload;
  }

  private get reference() {
    return this.s365Data?.reference;
  }

  public get esisMessage() {
    if (!this.s365Data?.sourcingCallBack) {
      return 'Your documents have been generated. Use the buttons below to download them.';
    }

    const clientDisplayName = this.clientDetails?.forename && this.clientDetails?.surname
      ? ` - ${this.clientDetails.forename} ${this.clientDetails.surname}`
      : '';

    return `Your documents have been generated and case ${this.reference}${clientDisplayName}
      within ${this.clientId} has been updated with the relevant production information.
      Use the button below if you would like to download a local copy of your documents.`;
  }

  public get downloadButtons(): (DownloadButtonDefinition & { enabled: boolean })[] {
    return this._downloadButtons.filter(x => x.condition?.() ?? true)
      .map(btn => ({
        ...btn,
        enabled: this.generatedDocumentTypes.includes(btn.docType),
      }));
  }

  public get showDownloadButtonDescription(): boolean {
    // According to requirements, description is only shown on BTL journey
    return this.lendingTypeService.currentLendingTypeCode === LendingTypeCode.Btl;
  }

  private createForm() {
    this.form = this.fb.group({
      details: this.fb.group({
        clientDetails: this.fb.group({
          clients: this.fb.array([
            this.fb.group({
              forename: new UntypedFormControl(''),
              surname: new UntypedFormControl(''),
            }),
          ]),
          postcode: new UntypedFormControl('', [
            Validators.maxLength(10),
          ]),
          referenceNumber: new UntypedFormControl(''),
        }),
        loanDetails: this.fb.group({
          mortgageType: new UntypedFormControl({ value: this.searchDetails.mortgageType, disabled: true }),
          purchaserType: new UntypedFormControl(this.searchDetails.purchaserType),
          propertyValue: new UntypedFormControl({ value: this.searchDetails.propertyValue, disabled: true }, [
            Validators.min(1),
            FormsValidators.integer,
          ]),
          loanAmount: new UntypedFormControl({ value: this.searchDetails.loanAmount, disabled: true }, [
            // Note that c.parent may be null when the component is being initialised
            c => c.value < (c.parent as UntypedFormGroup)?.controls.propertyValue.value
              ? null : { propertyValue: true },
          ]),
          ioLoanAmount: new UntypedFormControl({ value: this.searchDetails.interestOnlyAmount, disabled: true }),
          outstandingBalanceCurrentMortgage:
            new UntypedFormControl({ value: this.searchDetails.outstandingBalanceCurrentMortgage, disabled: true }),
          ltv: new UntypedFormControl('-'),
          termYears: new UntypedFormControl({ value: this.searchDetails.mortgageTerm.years, disabled: true }, [
            Validators.min(0),
          ]),
          termMonths: new UntypedFormControl({
            value: this.searchDetails.mortgageTerm.years
              ? (this.searchDetails.mortgageTerm.months ?? 0)
              : this.searchDetails.mortgageTerm.months,
            disabled: true,
          }, [
            Validators.min(0),
            Validators.max(11),
          ]),
          repaymentMethod: new UntypedFormControl({ value: this.searchDetails.repaymentMethod.value, disabled: true }),
        }),
        isAdvised: new UntypedFormControl('True', Validators.required),
      }),
      fees: this.fb.group({
        fees: this.fb.array([], this.feeTypeValidator.bind(this)),
        feeWaiver: new UntypedFormControl(''),
        procuration: this.fb.group({
          lenderIdx: new UntypedFormControl(null, [Validators.required]),
          rateId: new UntypedFormControl(null),
          procFeePaymentAmount: new UntypedFormControl(null, [
            FormsValidators.optionalNumber,
            FormsValidators.optionalPositive,
          ]),
          payableTo: new UntypedFormControl(''),
        }, {
          validators: [
            (ctrl: AbstractControl) => {
              // Either a rate must be selected or a custom amount must be entered.
              const { rateId, procFeePaymentAmount } = ctrl.value as EsisEorGenerationRequest['fees']['procuration'];
              const valid = (rateId !== null && rateId !== undefined) ||
                (procFeePaymentAmount !== null && procFeePaymentAmount !== undefined);
              return valid ? null : { required: true };
            },
          ],
        }),
      }),
      eor: this.fb.group({
        resultsGrid: this.fb.group({
          rowCount: new UntypedFormControl('', [
            Validators.min(0),
            FormsValidators.integer,
          ]),
          title: new UntypedFormControl(''),
          description: new UntypedFormControl(''),
        }),
        recommendationReason: new UntypedFormControl(''),
        additionalInformation: new UntypedFormControl(''),
      }),
      resiBtlProductsResults: new UntypedFormControl(''),
      bridgingProductsResults: new UntypedFormControl(''),
    });
  }

  private fetchEsisFormDetails() {
    if (!this.searchDetails.lendingTypeId) {
      return;
    }
    this.esisEorDocumentService
      .getFormDetails(this.product.lender, this.searchDetails.lendingTypeId, this.product.productCode)
      .subscribe(details => {
        this.esisFormDetails = details;
        this.setFeeAddToLoan();
        this.patchFormFromDetails();

        // If user has not filled in their document preferences or firm details, require them to do so now
        this.activePage = details.requiresMoreDetails
          ? EsisModalPage.DocumentPreferences
          : EsisModalPage.Form;
      });
  }

  private setFeeAddToLoan() {
    if (this.customProductCalculations?.customFeeCalculationScenarios) {
      this.esisFormDetails.fees.forEach(fee => {
        const updatedFee = this.customProductCalculations?.customFeeCalculationScenarios
          .find(updateFee=>updateFee.feeType === fee.type as ProductFeeType);
        if (updatedFee) {
          if (updatedFee.feeCalculationScenario !== FeeCalculationScenario.Exclude) {
            fee.addToLoan = updatedFee.feeCalculationScenario === FeeCalculationScenario.AddToLoan;
          }
        }
      });
    }
  }

  private patchFormFromDetails(): void {
    const { brokerFirmName, userEsisInformation } = this.esisFormDetails;
    const formValues = {
      fees: {
        procuration: {
          payableTo: brokerFirmName,
        },
      },
      details: {} as EsisDetails,
      eor: {
        resultsGrid: {
          rowCount: userEsisInformation?.evidenceOfResearch?.numberOfResults,
          title: userEsisInformation?.evidenceOfResearch?.resultsGridTitle,
          description: userEsisInformation?.evidenceOfResearch?.resultsGridDescription,
        },
        recommendationReason: userEsisInformation?.evidenceOfResearch?.reasonForRecommendation,
        additionalInformation: userEsisInformation?.evidenceOfResearch?.additionalInformation,
      },
    };

    if (this.clientDetails) {
      formValues.details.clientDetails = {
        postcode: this.clientDetails.postcode,
        clients: [{
          forename: this.clientDetails.forename,
          surname: this.clientDetails.surname,
        }],
      } as EsisClientDetails;

      if (!!this.clientDetails.client2Details) {
        // add new field to array before patching the value
        (this.form.get('details.clientDetails.clients') as UntypedFormArray).push(this.fb.group({
          forename: new UntypedFormControl(''),
          surname: new UntypedFormControl(''),
        }));

        formValues.details.clientDetails.clients.push({
          forename: this.clientDetails.client2Details.forename,
          surname: this.clientDetails.client2Details.surname,
        });
      }

    }

    this.form.patchValue(formValues);

    (this.form.get('fees.fees') as UntypedFormArray).clear();
    Object.values(ProductFeeType)
      // Packager fee will be added separately
      .filter(type => ProductFeeType[type] !== ProductFeeType.Packager)
      .map((type: string) => {
        const amount = this.product.fees[ProductFeeType[type]] as number;
        const lenderFee = this.esisFormDetails.fees?.find(f=>f.type === ProductFeeType[type]);
        if (amount > 0) {
          if (lenderFee) {
            this.createFee({ ...lenderFee, amount, isLenderFee: true });
          } else {
            this.createFee({ ...{type: ProductFeeType[type]}, amount, isLenderFee: true });
          }
        }
      });

    const customBrokerFees = this.customProductCalculations?.customFees?.filter(fee=>fee.feeName === 'Broker Fee');
    customBrokerFees?.forEach(brokerFee => {
      let amount = brokerFee.feeAmount;
      if (amount < 2) {
        const loanAmount = this.productsSearchService.getLoanAmount();
        amount = (loanAmount ?? 0) * amount;
      }
      brokerFee.type = ProductFeeType.Broker;
      this.createFee({ ...brokerFee, amount, isLenderFee: false });
    });

    if (this.productsSearchService.customProductCalculations.customFees?.length) {
      const customFees = this.productsSearchService.customProductCalculations?.customFees
        ?.filter(fee=>fee.feeName !== 'Broker Fee');
      for (const customFee of customFees) {
        customFee.type = ProductFeeType.Custom;
        customFee.customFeeName = customFee.feeName;
        let amount = customFee.feeAmount;
        if (amount < 2) {
          const loanAmount = this.productsSearchService.getLoanAmount();
          amount = (loanAmount ?? 0) * amount;
        }
        if (amount > 0) {
          this.createFee({ ...customFee, amount, isLenderFee: false });
        }
      }
    }
    // If the product is a packager product
    // add the fee directly from the product rather than from lenderhub
    if (ProductFeeType.Packager in this.product.fees) {
      const packagerFee = {
        type: ProductFeeType.Packager,
        amount: this.product.fees[ProductFeeType.Packager],
        whenPayable: WhenPayable.OnApplication,
        addToLoan: false,
        amountRefundable: 0,
        whenRefundable: WhenRefundable.NotRefundable,
        isLenderFee: true,
        customFeeName: feesDisplayNames[ProductFeeType.Packager],// 'Packager fee',
      } as Partial<CostsToBePaidDetailed & { isLenderFee: boolean; customFeeName: string }>;
      this.createPackagerFee(packagerFee);
    }
  }

  public get modalTitle(): string {
    return this.activePage === EsisModalPage.DocumentPreferences
      ? 'Additional Information Required'
      : `${this.product.lender} ${this.product.productDescription ?? ''}`;
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      const form = this.form.getRawValue();

      const numberOfResults = form.eor.resultsGrid.rowCount;
      let datasource = this.fullDatasource;

      if (numberOfResults) {
        datasource = datasource.slice(0, numberOfResults);
      }

      if (this.isBridging) {
        form.bridgingProductsResults = datasource.map(p => {
          return {
            id: p.id,
            maxLtv: p.maxLtv,
            aprc: p.aprc,
            monthlyRate: p.showTrueCost ? p.monthlyRate : '',
            trueCost: p.showTrueCost ? p.trueCost : '',
            grossLoan: p.showTrueCost ? p.grossLoan : '',
            grossLtv: p.showTrueCost ? p.grossLtv : '',
            fees: p.fees,
            rates: p.rates,
          };
        });
      } else {
        form.resiBtlProductsResults = datasource.map((p: Product) => {
          return {
            id: p.id,
            packager: p.packager,
            maxLtv: p.maxLtv,
            trueCost: p.trueCost,
            trueCostInitialPeriod: p.trueCostInitialPeriod,
            trueCostCustomPeriod: p.trueCostCustomPeriod,
            initialMonthlyPayment: p.initialMonthlyPayment,
            monthlyPaymentRevert: p.monthlyPaymentRevert,
            initialMonthlyPaymentInterestOnly: p.initialMonthlyPaymentInterestOnly,
            monthlyPaymentRevertInterestOnly: p.monthlyPaymentRevertInterestOnly,
            totalInterestPayable: p.totalInterestPayable,
            aprc: p.aprc,
            totalFees: this.productsUtils.calculateTotalFees(p, this.productsSearchService),
            revertRate: p.revertRate,
            initialRate: p.initialRateValue,
            lenderCriteriaOutcome: p.lenderOutcomeResult,
            lenderAffordabilityMet: p.lenderAffordabilityMet,
            outstandingBalance: p.outstandingBalance,
          };
        });
      }
      form.totalProductCount = this.fullDatasource.length;

      form.originalRequestModel = this.originalRequest;
      form.selectedProduct = this.product;
      form.selectedProductRank = this.productRank;
      form.selectedProductFees = this.productsUtils.getProductsFees(this.product, this.productsSearchService);
      form.selectedProductCustomFeeAmount =  this.productsUtils.getTotalCustomFeeAmount(this.productsSearchService);
      form.selectedProductAssumedLegalFeeAmount = this.productsUtils
        .getAssumedLegalFee(this.product, this.productsSearchService);
      form.gridState = this.productGridState;
      form.selectedProductFilters = this.productsFilterService.getSelectedFilterNames(this.filterContext);
      form.details.loanDetails.lendingTypeCode = this.searchDetails.lendingTypeCode;
      form.details.loanDetails.productTypeExtended = this.searchDetails.lendingTypeCode === 'BTL'
        ? null
        : this.searchDetails.productTypeExtended,
      form.details.loanDetails.btlType = this.searchDetails.btlType;
      form.details.loanDetails.newBuild = this.searchDetails.newBuild;
      form.details.loanDetails.propertyType = this.searchDetails.propertyType;
      form.details.loanDetails.helpToBuy = this.searchDetails.helpToBuy;
      form.details.loanDetails.remortgageType = this.searchDetails.remortgageType;
      form.details.loanDetails.limitedCompanyPurchase = this.searchDetails.limitedCompanyPurchase;
      form.details.loanDetails.portfolioLandlord = this.searchDetails.portfolioLandlord;
      form.details.loanDetails.currentMortgageLender = this.searchDetails.currentMortgageLender;
      form.details.loanDetails.productTransferInitialDate = this.searchDetails.productTransferInitialDate;
      form.details.loanDetails.purchasePriceShare = this.searchDetails.purchasePriceShare;
      if (this.igniteService.affordabilityModelRequest) {
        this.updateLoanDetailsFromModelRequest(this.igniteService.affordabilityModelRequest, form);
      } else if (this.productsSearchService.productsModelRequest) {
        this.updateLoanDetailsFromModelRequest(this.productsSearchService.productsModelRequest, form);
      }

      form.igniteLenders = this.igniteLenders;
      form.feeCalculationScenarios =
        this.mapFeeCalculationScenariosRequest(this.customProductCalculations?.customFeeCalculationScenarios);
      form.fees.procuration.procFeePaymentAmount = this.isEorOnly ? 0 :
        this.getProcurationFeeAmount(form.fees.procuration);
      form.deductCashback = this.customProductCalculations?.deductCashback;
      form.details.propertyDetails = {
        location: this.searchDetails.location ??
          this.searchDetails.propertyDetails?.location,
        propertyType: this.searchDetails.propertyType ??
          this.searchDetails.propertyDetails?.propertyType} as PropertyDetails;
      form.customTrueCostPeriod = this.customTrueCostPeriod ?? 'xx';
      form.outcomes = this.outcomeIds ?? [];
      form.isCriteriaV2 = this.isCriteriaV2;
      form.affordabilityRequest = this.igniteService.affordabilityModelRequest;
      form.affordabilityResponse = this.igniteCommonDataService.results?.quotes
        .filter(q => q.maximumAffordableLoanAmount !== null);
      if (this.isCriteriaV2) {
        form.criteriaV2Contexts = this.criteriaV2Contexts;
      }
      this.isGeneratingDocs = true;
      this.analyticsService.trackClickWithDetails('Generate Documents', this.product.productCode);

      try {
        const productCollectionType = this.isBridging ? ProductCollectionType.Bridging : ProductCollectionType.ResiBtl;

        const generationResult = await firstValueFrom(
          this.esisEorDocumentService.generateDocuments(form, this.endpointSuffix, productCollectionType),
        );

        this.activePage = EsisModalPage.DocDownload;
        this.generatedDocumentId = generationResult.documentsId;
        this.generatedDocumentTypes = generationResult.typesGenerated;
        if (this.s365Data?.sourcingCallBack) {
          const hasSourcingEvents = this.s365Data.events?.some(x=>x.eventType === SsoCallBackEventType.Sourcing);
          if (hasSourcingEvents) {
            this.esisEorDocumentService
              .documentGenerationSmartr365Callback(this.mapSmartr365CallbackRequest).subscribe();
          }
        }
      } finally {
        this.isGeneratingDocs = false;
      }
    } else {
      this.markAllControlsAsTouched(Object.values(this.form.controls));
      Object.keys(EsisModalTab)
        .filter(x => isNaN(+x))
        .map(x => EsisModalTab[x] as EsisModalTab)
        .some(tab => {
          const isInvalid = this.isSectionInvalid(tab);
          if (isInvalid) {
            this.activeTab = tab;
          }
          return isInvalid;
        });
      setTimeout(() => this.scrollToFirstInvalidControl(), 200);
    }
  }

  private updateLoanDetailsFromModelRequest(modelRequest: any, form: any): void {
    if ('repaymentVehicle' in modelRequest) {
      form.details.loanDetails.repaymentVehicle = modelRequest.repaymentVehicle;
    }
    if ('interestOnlyAmount' in modelRequest) {
      form.details.loanDetails.interestOnlyAmount = modelRequest.interestOnlyAmount;
    }
    if ('equityValue' in modelRequest) {
      form.details.loanDetails.equityValue = modelRequest.equityValue;
    }
  }

  private mapFeeCalculationScenariosRequest(feeCalculationScenarios: CustomProductFees[]) {
    return feeCalculationScenarios.map(c => {
      return {
        feeName: c.feeType === ProductFeeType.Custom ? c.feeName : feesDisplayNames[c.feeType],
        feeCalculationScenario: c.feeCalculationScenario,
      };
    });
  }

  private getProcurationFeeAmount(procurationFormSection: EsisEorGenerationRequest['fees']['procuration']): number {
    // If a rate has been selected (instead of a custom amount being entered), put that rate's value into the amount
    if (procurationFormSection.procFeePaymentAmount) {
      return procurationFormSection.procFeePaymentAmount;
    }
    if (procurationFormSection.rateId) {
      // Do not need to filter by Lender, because the rateId is universally unique between lenders and clubs.
      const selectedRate = this.esisFormDetails.procFeePaymentRates
        .flatMap(l => l.clubRates)
        .flatMap(c => c.rates)
        .find(r => r.rateId === procurationFormSection.rateId);
      if (!selectedRate) {
        throw new Error(`Could not find an expected rate with ID ${procurationFormSection.rateId}.`);
      }
      return clamp(
        (selectedRate.grossPaymentPercent * (this.searchDetails.loanAmount ?? 0)) + selectedRate.grossPaymentAdditional,
        selectedRate.grossPaymentMin ?? 0,
        selectedRate.grossPaymentMax ?? Number.MAX_VALUE);
    }
    throw new Error('Could not get procuration fee amount.');
  }

  public async saveOneTimeForms(): Promise<void> {
    // firmDetailsForm will be undefined if the user is not an SSO since non-SSO users don't see this form
    // When submitting, mark both forms as touched so that validation errors appear on both
    this.documentPreferencesForm.markAllAsTouched();
    this.firmDetailsForm?.markAllAsTouched();

    const isSsoUser = await firstValueFrom(this.isSsoUser$);
    if (!this.documentPreferencesForm.isValid || (isSsoUser && !this.firmDetailsForm.isValid)) {
      setTimeout(() => this.scrollToFirstInvalidControl(), 200);
      return;
    }

    // If both forms are valid (or just doc prefs valid if firm details isn't present)
    await Promise.all([
      this.documentPreferencesForm.saveDocPreferences(false),
      this.firmDetailsForm?.saveFirmDetails(false) ?? Promise.resolve(),
    ]);
    this.activePage = EsisModalPage.Loading;
    this.fetchEsisFormDetails();
  }

  public markAllControlsAsTouched(abstractControls: AbstractControl[]): void {
    abstractControls.forEach(abstractControl => {
      if (abstractControl instanceof UntypedFormControl) {
        (abstractControl as UntypedFormControl).markAsTouched({onlySelf: true});
      } else if (abstractControl instanceof UntypedFormGroup) {
        (abstractControl as UntypedFormGroup).markAsTouched({onlySelf: true});
        this.markAllControlsAsTouched(Object.values((abstractControl as UntypedFormGroup).controls));
      } else if (abstractControl instanceof UntypedFormArray) {
        (abstractControl as UntypedFormArray).markAsTouched({onlySelf: true});
        this.markAllControlsAsTouched((abstractControl as UntypedFormArray).controls);
      }
    });
  }

  public createFee(
    model: Partial<CostsToBePaidDetailed & { isLenderFee: boolean; customFeeName: string }> | null = null,
  ) {
    (this.form.get('fees.fees') as UntypedFormArray).push(this.fb.group({
      type: new UntypedFormControl(model?.type ?? '', [Validators.required]),
      amount: new UntypedFormControl(model?.amount, [Validators.required, Validators.min(0.01)]),
      whenPayable: new UntypedFormControl(model?.whenPayable, [Validators.required, Validators.min(1)]),
      addToLoan: new UntypedFormControl(model?.type === ProductFeeType.Broker ?
        false : model?.addToLoan ?? false),
      amountRefundable: new UntypedFormControl(
        model?.amountRefundable, [Validators.required, this.amountRefundableValidator],
      ),
      whenRefundable: new UntypedFormControl(model?.whenRefundable, [Validators.required, Validators.min(1)]),
      isLenderFee: new UntypedFormControl(model?.isLenderFee ?? false),
      customFeeName: new UntypedFormControl(model?.customFeeName,
        [FormsValidators.noLeadingWhitespace, FormsValidators.noTrailingWhitespace]),
      payableTo: this.getPayableToControl(model),

      // Unique ID for this fee to allow it to be tracked properly in the UI. The API will ignore this.
      uiId: new UntypedFormControl(newGuid()),
    }));
  }

  public createPackagerFee(
    model: Partial<CostsToBePaidDetailed & { isLenderFee: boolean; customFeeName: string }> | null = null,
  ) {
    (this.form.get('fees.fees') as UntypedFormArray).push(this.fb.group({
      type: new UntypedFormControl({value: model?.type ?? '', disabled: true},
        [Validators.required]),
      amount: new UntypedFormControl(
        {value: model?.amount, disabled: true}, [Validators.required, Validators.min(0.01)],
      ),
      whenPayable: new UntypedFormControl(
        {value: model?.whenPayable, disabled: true}, [Validators.required, Validators.min(1)],
      ),
      addToLoan: new UntypedFormControl({value: model?.addToLoan ?? false, disabled: true}),
      amountRefundable: new UntypedFormControl(
        {value: model?.amountRefundable, disabled: true}, [Validators.required, this.amountRefundableValidator],
      ),
      whenRefundable: new UntypedFormControl(
        {value: model?.whenRefundable, disabled: true}, [Validators.required, Validators.min(1)],
      ),
      isLenderFee: new UntypedFormControl({value: model?.isLenderFee ?? false, disabled: true}),
      customFeeName: new UntypedFormControl({value: model?.customFeeName, disabled: true},
        [FormsValidators.noLeadingWhitespace, FormsValidators.noTrailingWhitespace]),
      payableTo: this.getPayableToControl(model),

      // Unique ID for this fee to allow it to be tracked properly in the UI. The API will ignore this.
      uiId: new UntypedFormControl(newGuid()),
    }));
  }

  public removeFee(index: number): void {
    (this.form.get('fees.fees') as UntypedFormArray).removeAt(index);
  }

  private amountRefundableValidator(control: AbstractControl): ValidationErrors | null {
    // If the fee that this refundable amount is for is refundable, run a required and a min validator on it.
    if (
      +(control.parent?.getRawValue() as CostsToBePaidDetailed)?.whenRefundable as WhenRefundable !==
      WhenRefundable.NotRefundable
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return Validators.compose([Validators.required, Validators.min(0.01)])!(control);
    }
    return null;
  }

  private feeTypeValidator(): ValidationErrors | null {
    const feeArray = this.form?.get('fees.fees') as UntypedFormArray;
    if (feeArray?.value.filter((v: { type: string })=>v.type === 'broker_fee')?.length > 2) {
      return {brokerFeeDuplicated: true};
    }
    return null;
  }

  public downloadDocument(type: GeneratedLendingDocumentType): void {
    // TODO: figure out what's going on with person ID
    const personId = '00000000-0000-0000-0000-000000000000';
    const { forename, surname } = this.form.value.details.clientDetails.clients[0];

    this.esisEorDocumentService.downloadDocument(type, personId, this.generatedDocumentId)
      .subscribe(file => downloadBlob(file,
        `${GeneratedLendingDocumentType[type].toLowerCase()}-${forename.toLowerCase()}_${surname.toLowerCase()}.pdf`));
  }

  public isSectionInvalid(tab: EsisModalTab): boolean {
    // Currently the summary tab only has two dropdowns - one for lender and one for product (in the procuration group)
    switch (tab) {
      case EsisModalTab.ProductSummary:
        return this.invalid('fees.procuration');
      case EsisModalTab.AdditionalInformation:
        // This is everything APART from the above procuration group
        return ['details', 'fees.fees', 'fees.feeWaiver', 'eor'].some(path => this.invalid(path));
    }
  }

  private scrollToFirstInvalidControl(): void {
    this.modalElement.nativeElement
      .querySelector('input.is-invalid, select.is-invalid, .invalid-feedback')?.closest('.form-section')
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
  }

  public dismiss(): void {
    this.modalService.dismiss(null);
  }

  private get mortgageTermInMonths() : number {
    return ((this.searchDetails.mortgageTerm.years ?? 0) * 12) + (this.searchDetails.mortgageTerm.months ?? 0);
  }

  public get totalFees():number {
    return this.product.totalFees ?? 0;
  }

  private get mapSmartr365CallbackRequest() : IgnitePlusSourcingCallbackRequest {

    // Since the user may have changed fees via the docs modal, need to re-calculate the true cost/aprc/etc.
    const [interestOnlyLoanAmount, repaymentLoanAmount] = Loan.getLoanAmount({
      repaymentMethod: this.searchDetails.repaymentMethod.value,
      loanAmount: this.searchDetails.loanAmount ?? 0,
      interestOnlyAmount: this.searchDetails.interestOnlyAmount ?? 0,
    });

    const { fees, procuration } = this.form.get('fees')?.getRawValue() as EsisEorGenerationRequest['fees'];

    const calculateFees = (feeArray: any[], filterFn: (fee: any) => boolean, subtractValuation: boolean = false) => {
      return feeArray
        .filter(filterFn)
        .map(fee => {
          if (subtractValuation && fee.type === ProductFeeType.Valuation &&
        this.product.valRefundProductIncentive === true) {
            // Subtract valuation fee if valRefundProductIncentive is true and it's not added to the loan
            return 0;
          }
          return +fee.amount;
        })
        .reduce((total, fee) => total + fee, 0);
    };

    const loanFees = calculateFees(fees, f => f.addToLoan, true); // Sum fees to be added to the loan
    const additionalFees = calculateFees(fees, f => !f.addToLoan, true); // Sum fees not added to the loan


    const calculationResult = this.productsCalculationService.performLoanCalculation(
      new Loan(
        repaymentLoanAmount,
        interestOnlyLoanAmount,
        loanFees,
        additionalFees,
        this.customProductCalculations?.deductCashback ? (this.product.cashback ?? 0) : 0,
        this.mortgageTermInMonths,
        Loan.getLoanPeriodsFromProduct(this.product),
      ));

    const getFeeAmount = (type: ProductFeeType) =>
      fees.filter(f => +f.type === type).reduce((total, fee) => total + +fee.amount, 0);

    const customFees = fees.filter(f => +f.type === ProductFeeType.Custom)
      .map(f => {
        return {
          name: f['customFeeName'],
          value: +f.amount,
        } as ICustomFee;
      });

    return {
      data: {loanAmount: this.searchDetails.loanAmount ?? 0,
        propertyValue: this.searchDetails.propertyValue ?? 0,
        lendingType: this.searchDetails.lendingTypeCode ?? '',
        mortgageType: MortgageType[this.searchDetails.mortgageType] ?? '',
        purchaseType: PurchaserType[this.searchDetails.purchaserType] ?? '',
        repaymentType: RepaymentMethod[this.searchDetails.repaymentMethod.value ?? ''] ?? '',
        purchasePriceShare: this.searchDetails.purchasePriceShare ?? 0,
        mortgageTermMonths: this.mortgageTermInMonths,
        productCode: this.product.productCode,
        productName: this.product.productDescription,
        uniqueProductReference: this.product.uniqueProductReference,
        maxLtv: this.product.maxLtv,
        aprc: roundToNearest(calculationResult.aprc * 100, 0.1),
        initialPayRate: this.product.initialRateValue ?? 0,
        initialRatePeriodMonths : this.product.initialPeriod ?? 0,
        initialRatePeriodUntil: this.product.initialPeriodUntil,
        lenderName: this.product.lender,
        productType: this.product.initialRateType ?? '',
        revertRate: this.product.revertRate ?? 0,
        earlyRepaymentCharge: this.product.earlyRepaymentChargesErcApplied,
        earlyRepaymentChargeDescription: '',
        ercPeriods: (this.product.ercRanks ?? [])
          .map((rate, idx) => ({ ordinal: idx + 1, rate }))
          .filter(({ rate }) => rate !== null),
        totalFees : fees.reduce((total, fee) => total + +fee.amount, 0),
        cashback: this.product.cashback ?? 0,
        documents: this.documentsToSend(),
        costs : {
          feesAddedToLoan: this.customProductCalculations?.customFeeCalculationScenarios?.some(fee =>
            fee.feeCalculationScenario === FeeCalculationScenario.AddToLoan),
          cashbackDeducted: this.customProductCalculations?.deductCashback ?? false,
          trueCostPeriodMonths:
            this.productsCalculationService.calculateNearestYearLoanPeriod(this.product.rates[0]?.initialPeriod) ?? 0,
          monthlyPaymentInitialPeriod: roundToPence(calculationResult.initialMonthlyPayment),
          monthlyPaymentAfterInitialPeriod: roundToPence(calculationResult.monthlyPaymentRevert),
          trueCostOverPeriod: calculationResult.trueCostCustom ?? 0,
          trueCostOverTerm: roundToPence(calculationResult.trueCost ?? 0),
          fees: {
            grossProcurationFee: this.isEorOnly
              ? null
              : roundToPence(this.getProcurationFeeAmount(procuration)),
            valuationFee: roundToPence(getFeeAmount(ProductFeeType.Valuation)),
            bookingFee: roundToPence(getFeeAmount(ProductFeeType.Booking)),
            brokerFee: roundToPence(getFeeAmount(ProductFeeType.Broker)),
            arrangementFee: roundToPence(getFeeAmount(ProductFeeType.Arrangement)),
            chapsFee: roundToPence(getFeeAmount(ProductFeeType.Chaps)),
            conveyancingFee: roundToPence(getFeeAmount(ProductFeeType.Conveyancing)),
            deedsReleaseFee: roundToPence(getFeeAmount(ProductFeeType.DeedsRelease)),
            disbursementFee: roundToPence(getFeeAmount(ProductFeeType.Disbursement)),
            packagerFee: roundToPence(getFeeAmount(ProductFeeType.Packager)),
            processingFee: roundToPence(getFeeAmount(ProductFeeType.Processing)),
            mortgageExitFee: roundToPence(getFeeAmount(ProductFeeType.MortgageExit)),
            higherLendingFee: roundToPence(getFeeAmount(ProductFeeType.HigherLending)),
            redemptionAdministrationCharge: roundToPence(getFeeAmount(ProductFeeType.RedemptionAdministration)),
            furtherAdvanceFee: roundToPence(getFeeAmount(ProductFeeType.FurtherAdvance)),
            customFees: customFees,
          },
        },
      },
      events: this.s365Data?.events?.filter(x=>x.eventType === SsoCallBackEventType.Sourcing),
      clientDetails: this.s365Data?.clientDetails,
      organisationId: this.s365Data?.organisationId,
      reference: this.s365Data?.reference,
    };
  }

  private documentsToSend() : IgnitePlusCallbackDocument[] {
    const { forename, surname } = this.form.value.details.clientDetails.clients[0];
    const personId = '00000000-0000-0000-0000-000000000000';

    const documentDetails : IgnitePlusCallbackDocument[] = [
      {
        filename: `${GeneratedLendingDocumentType[GeneratedLendingDocumentType.Eor].toLowerCase()}` +
          `-${forename.toLowerCase()}_${surname.toLowerCase()}.pdf`,
        documentType: 'Evidence Of Research',
        url: `${this.configService.config.clubHubApiUrl}/api/v2/SmartrFitPlus/retrieveDocument/` +
          `${personId}/${this.generatedDocumentId}/${GeneratedLendingDocumentType.Eor}`,
      },
      {
        filename: `${GeneratedLendingDocumentType[GeneratedLendingDocumentType.Esis].toLowerCase()}` +
          `-${forename.toLowerCase()}_${surname.toLowerCase()}.pdf`,
        documentType: 'European Standardised Information Sheet',
        url: `${this.configService.config.clubHubApiUrl}/api/v2/SmartrFitPlus/retrieveDocument/` +
          `${personId}/${this.generatedDocumentId}/${GeneratedLendingDocumentType.Esis}`,
      },
      {
        filename: `${GeneratedLendingDocumentType[GeneratedLendingDocumentType.Illustration].toLowerCase()}` +
          `-${forename.toLowerCase()}_${surname.toLowerCase()}.pdf`,
        documentType: 'Illustration',
        url: `${this.configService.config.clubHubApiUrl}/api/v2/SmartrFitPlus/retrieveDocument/` +
          `${personId}/${this.generatedDocumentId}/${GeneratedLendingDocumentType.Illustration}`,
      },
    ];
    // filter docs by enum on end of the url
    return documentDetails
      .filter(x => this.downloadButtons.find(y => +y.docType === +(x.url.split('/').pop() ?? ''))?.enabled);
  }

  public get clientId() {
    return this.authService.user?.client_id;
  }

  private get brokerName(): string {
    return this.esisFormDetails?.userEsisInformation?.broker?.brokerName ?? '';
  }

  private get lenderName(): string {
    return this.product.lender;
  }

  private getPayableToControl(
    model: Partial<CostsToBePaidDetailed & { isLenderFee: boolean; customFeeName: string }> | null = null,
  ) {
    return new UntypedFormControl(
      model?.type === ProductFeeType.Custom
        ? (model?.payableTo ?? '')
        : model?.type === ProductFeeType.Broker
          ? this.brokerName
          : this.lenderName,
      [Validators.required, Validators.maxLength(100)],
    );
  }
}
