import { AffordabilityRequest, BtlAffordabilityRequest, ClientDetails, ProductType } from 'apps/shared/src/models';

export enum SsoCallBackEventType
{
    Sourcing,
    Affordability,
}

export interface CurrentProduct {
    lender: string;
    endDate: Date;
}

export interface ProductCaseData {
    productType?: ProductType;
    currentProduct: CurrentProduct;
    purchasePriceShare?: number;
}

export interface SsoCallbackAuth
{
    bearer?: string;
}

export interface SsoCallbackEvent
{
    callbackUrl: string;
    authorization: SsoCallbackAuth;
    customData: string;
    eventType: SsoCallBackEventType;
}

export interface CaseData {
    reference: string;
    affordabilityInput : AffordabilityRequest | BtlAffordabilityRequest;
    productInput: ProductCaseData;
    clientDetails: ClientDetails;
    sourcingCallBack: boolean;
    organisationId: string;
    events?: SsoCallbackEvent[];
    lenderMatched: boolean;
}
