/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  CellAwareFunc,
  CellClickEvent,
  DropdownFilterType,
  GridStateModel,
  ISimpleGridColumn,
  ISimpleGridFilterOperation,
  ISimpleGridFilterPresetOption,
  ISimpleGridRenderContext,
  ISortOption,
  SimpleGridColumnRenderer,
  SimpleGridColumnRendererTarget,
  SimpleGridColumnType,
  SimpleGridFilterChangeType,
  SimpleGridFilterChangedEventArgs,
  SimpleGridFilterOperator,
  SimpleGridSelectionMode,
  SortDir,
} from '../../models';
import isObjectEqual from 'lodash-es/isEqual';
import orderBy from 'lodash-es/orderBy';
import uniqBy from 'lodash-es/uniqBy';
import { CurrencyPipe, DOCUMENT, DecimalPipe, NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase,
  NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { Instance as PopperInstance, createPopper } from '@popperjs/core';
import formatDate from 'date-fns/format';
import parseISODate from 'date-fns/parseISO';
import isDateEqual from 'date-fns/isEqual';
import parseDate from 'date-fns/parse';
import isValidDate from 'date-fns/isValid';
import isDate from 'date-fns/isDate';
import startOfDay from 'date-fns/startOfDay';
import isDateBefore from 'date-fns/isBefore';
import isDateAfter from 'date-fns/isAfter';
import { KeyCodes } from '../../constants';
import { Datasource, DatasourceResult } from './datasources/datasource';
import { Subscription } from 'rxjs';
import { RawDatasource, toDatasource } from './datasources';
import { GridExtraStyles } from '@msslib/components/simple-grid/models/grid-extra-styles';
import { StartCasePipe } from '@msslib/pipes';
import { toCsv } from '@msslib/helpers';
import { NgbDate, NgbPagination, NgbPaginationEllipsis, NgbPaginationNext, NgbPaginationNumber,
  NgbPaginationPrevious, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MultiselectFilterComponent } from '../grid/components/multiselect-filter/multiselect-filter.component';
import { AttributesFromObjectDirective } from '../../directives/attributes-from-object.directive';

const defaultColumnOptions = {
  type: SimpleGridColumnType.String,
  prefix: '',
  suffix: '',
  discreteFilter: false,
  discreteMultiSelect: false,
  hidden: false,
} as ISimpleGridColumn;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template[sgCellTemplate]',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SimpleGridCellTemplate {
  @Input() public columnName: string;
  public constructor(public templateRef: TemplateRef<ISimpleGridRenderContext>) { }
}

@Component({
  selector: 'lib-simple-grid',
  styleUrls: ['./simple-grid.component.scss'],
  templateUrl: './simple-grid.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    NgbTooltip,
    AttributesFromObjectDirective,
    MultiselectFilterComponent,
    NgTemplateOutlet,
    NgStyle,
    FormsModule,
    NgbPagination,
    NgbPaginationPrevious,
    NgbPaginationNext,
    NgbPaginationNumber,
    NgbPaginationEllipsis,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
})
export class SimpleGridComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  @ContentChildren(SimpleGridCellTemplate) public inlineTemplates: QueryList<SimpleGridCellTemplate>;
  private _inlineTemplateChangesSubscription : Subscription | undefined;

  @ViewChild('tableContainer') public tableContainer: ElementRef<HTMLDivElement>;
  @ViewChild('filterDropdown') public filterDropdown: TemplateRef<{ $implicit: ISimpleGridColumn }>;

  @Input({ required: true }) public columns: (Partial<ISimpleGridColumn> & { name: string })[] = [];
  @Input() public defaultColumnHeaderFilterMode: ISimpleGridColumn['headerFilter'] = false;
  @Input() public defaultColumnDropdownFilterMode: ISimpleGridColumn['dropdownFilter'] = DropdownFilterType.None;
  @Input() public defaultColumnSortable: ISimpleGridColumn['sortable'] = true;
  @Input() public paginate = true;
  @Input() public pageSizeInit = 15;
  @Input() public page = 1;
  @Input() public sortOn: string | null = null;
  @Input() public sortDir: SortDir | null = 'asc';
  @Input() public fallbackSort: [string, SortDir][] = [];
  @Input() public noRecordsMessage: string;

  /** Applied to the `<table>` element. */
  @Input() public classList: NgClass['ngClass'] = '';

  /** Applied to each `<tr>` element in the table body. */
  @Input() public rowClassList: NgClass['ngClass'] = '';

  /** Applied to each `<td>` element in the table body. */
  @Input() public rowCellClassList: NgClass['ngClass'] = '';

  /** Applied to each individual `<th>` element in the header. */
  @Input() public headerCellClassList: NgClass['ngClass'] = '';

  @Input() public fixedLayout = false;
  @Input() public tableHover = false;
  @Input() public responsive = false;
  @Input() public sticky = false;
  @Input() public stickyShift = 0;
  @Input() public wordBreakHeadings = true;
  @Input() public textSmall = false;
  @Input() public showSpinner = true;
  @Input() public tooltip: string | TemplateRef<void> | null = null;
  @Input() public tooltipExcludeColumns: string[] = [];
  @Input() public breakHeaderRow = false;
  @Input() public extraStylesExpression: (rowData: any) => GridExtraStyles | null;
  @Input() public pageSizeChoices: number[] = [];
  @Input() public minGridHeight = '';
  @Input() public rowSelectionMode = SimpleGridSelectionMode.None;
  @Input() public toggleRowOnClicked = true;
  /** A function that can uniquely identify rows.
   * This is required for determining which rows are selected and must be non-null if using row selection. */
  @Input() public trackRowByFn: (row: any) => any;

  @Input() public set extraInformationContent(value: string) {
    this.extraInfoHidden = !value;
    this.extraInfoContent = value;
  }

  /** If not undefined, table will allow for child rows to be present underneath parent. The child items should have the
   * same type/properties as the parent, and will use the same columns and column renderers.
   * This can either be the name of a property on the parent item, or a function that resolves to the child items.
   * An expand/collapse button and indentation will be added to the nth column as specified by `childRowColumnIndex`.
   * `trackByRowFn` should also be provided for this functionality to work as expected. */
  @Input() public childRowSelector: string | ((row: any) => any[]) | undefined = undefined;
  @Input() public childRowColumnIndex = 0;
  @Input() public childRowIndent = 20;

  @Output() public pageChange = new EventEmitter<number>();
  @Output() public filtersChange = new EventEmitter<SimpleGridFilterChangedEventArgs>();
  @Output() public sortOnChange = new EventEmitter<string | null>();
  @Output() public sortDirChange = new EventEmitter<SortDir | null>();
  @Output() public sortChange = new EventEmitter<[string, SortDir]>();
  @Output() public cellClick = new EventEmitter<CellClickEvent>();
  @Output() public loadingChange = new EventEmitter<boolean>();
  @Output() public selectedRowsChange = new EventEmitter<readonly any[]>();

  private _isInitialised = false;
  private _rawDatasource: RawDatasource;
  private _datasource: Datasource | null;
  private _datasourceLatestResult: DatasourceResult | null;
  private _gridFullDatasourceResult: unknown[];
  private _datasourceChangeSubscription: Subscription | undefined;
  public isLoading = false;
  public isFixedGridVisible = true;
  public columnData: ISimpleGridColumn[] = [];

  public readonly filters = new Map<string, ISimpleGridFilterOperation>();
  private _selectedRowKeys = new Set<any>();
  private filterMenuView: EmbeddedViewRef<{ $implicit: ISimpleGridColumn }> | null;
  private filterMenuEl: HTMLElement | null;
  private openFilterColumn: ISimpleGridColumn | null;
  private filterMenuEvt: (e: MouseEvent) => void;
  private popper: PopperInstance | null;
  public pageSize: number;
  private defaultSortOn: string | null;
  private defaultSortDir: SortDir | null;
  public extraInfoHidden = true;
  public extraInfoContent: string;
  private expandedRows = new Set();

  private readonly defaultRenderers = {
    [SimpleGridColumnType.Number]: (number, _row, _target, options) => {
      if (number === undefined || number === null) {
        return '';
      }
      const { minIntegerDigits = 1, minFractionDigits = 0, maxFractionDigits = 3 } = options;
      return this.decimalPipe.transform(number, `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`);
    },
    [SimpleGridColumnType.Date]: date => date === undefined || date === null ?
      '' : formatDate(typeof date === 'string' ? parseISODate(date) : date, 'dd/MM/yyyy'),
    [SimpleGridColumnType.DateTime]: date => date === undefined || date === null ? ''
      : formatDate(typeof date === 'string' ? parseISODate(date) : date, 'dd/MM/yyyy HH:mm'),
    [SimpleGridColumnType.Currency0]: val => val === undefined || val === null ? ''
      : this.currencyPipe.transform(val, 'GBP', 'symbol', '1.0-0'),
    [SimpleGridColumnType.Currency2]: val => val === undefined || val === null ? ''
      : this.currencyPipe.transform(val, 'GBP', 'symbol', '1.2-2'),
    [SimpleGridColumnType.Boolean]: value => value === undefined || value === null ? ''
      : value ? 'Yes' : 'No',
  } as Record<number, SimpleGridColumnRenderer>;

  private readonly columnDataDependentFields = [
    'columns',
    'defaultColumnHeaderFilterMode',
    'defaultColumnDropdownFilterMode',
    'defaultColumnSortable',
  ] satisfies (keyof this)[];

  public today = new Date();
  public columnType = SimpleGridColumnType;
  public filterOperator = SimpleGridFilterOperator;
  public formatDate = formatDate;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private startCasePipe: StartCasePipe,
    private decimalPipe: DecimalPipe,
  ) { }

  public ngOnInit(): void {
    this._isInitialised = true;
    this.pageSize = this.pageSizeInit;
    this.updateData();

    this.defaultSortOn = this.sortOn;
    this.defaultSortDir = this.sortDir;
  }

  public ngAfterContentInit(): void {
    // In case any of the columns use inline templates, we need to refresh the column data to reference these templates
    // properly, and monitor for changes. The inlineTemplates QueryList is first available here - not in ngOnInit.
    this.refreshColumnData();
    this._inlineTemplateChangesSubscription = this.inlineTemplates.changes.subscribe(() => this.refreshColumnData());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('sortOn' in changes || 'sortDir' in changes) {
      const sortDir: SortDir = changes?.sortDir?.currentValue ?? this.sortDir;
      const sortOn = changes.sortOn?.currentValue ?? this.sortOn;
      this.setSort(sortOn, sortDir);
    }
    if ('pageSize' in changes) {
      this.updateData();
    }

    // If we use a property for columnData, it causes re-draws of the <td> elements each change (as the object is
    // technically a new instance) which can cause stutters for large tables. Instead, we only want to re-calculate
    // this when the columns input or any of the 'default' values changes.
    if (this.columnDataDependentFields.some(k => k in changes)) {
      this.refreshColumnData();
    }
  }

  public ngOnDestroy() {
    this.closeFilter();
    this._datasource?.dispose?.();
    this._datasourceChangeSubscription?.unsubscribe();
    this._inlineTemplateChangesSubscription?.unsubscribe();
  }

  @Input()
  public get datasource() {
    return this._rawDatasource;
  }
  public set datasource(value) {
    if (isObjectEqual(this._rawDatasource, value)) {
      // Do not dispose and recreate/subscribe to datasource if it's the same
      return;
    }
    this._rawDatasource = value;
    this._datasource?.dispose?.();
    this._datasourceChangeSubscription?.unsubscribe();
    this._datasource = toDatasource(value);
    this._datasourceChangeSubscription = this._datasource?.notifyChanged?.subscribe(() => this.updateData());
    this.updateData();
  }

  public get showTooltip() {
    return this.tooltip !== null && this.tooltip !== '';
  }

  public get stickyTop() {
    return this.stickyShift ? `${this.stickyShift}px` : '0';
  }

  public get tooltipIsString() {
    return typeof this.tooltip === 'string';
  }

  /** Total number of items in the dataset. */
  public get totalItemCount() {
    return this._datasourceLatestResult?.totalCount ?? 0;
  }

  /** Data for the items on the current page. */
  public get pageData() {
    return this._datasourceLatestResult?.items ?? [];
  }

  /**
   * Generally does not need to be called outside of the simple-grid component, unless properties on a column have
   * changed without the columns array itself being changed.
   */
  public refreshColumnData() {
    this.columnData = this.columns
      .filter(c => c.hidden !== true)
      .map(c => this.column(c.name) as ISimpleGridColumn);
  }

  public getExtraCellStyles(rowData: any) {
    if (!!this.extraStylesExpression && this.extraStylesExpression instanceof Function) {
      return this.extraStylesExpression(rowData)?.itemCellStyles;
    }
  }

  public getExtraRowStyles(rowData: any) {
    if (!!this.extraStylesExpression && this.extraStylesExpression instanceof Function) {
      return this.extraStylesExpression(rowData)?.itemRowStyles;
    }
  }

  public get allItems() {
    return this._datasource?.allItems ?? [];
  }

  public onCellClicked(
    row: any,
    parentRow: any,
    rowIndex: number,
    parentRowIndex: number | undefined,
    column: ISimpleGridColumn,
    columnIndex: number,
  ): void {
    const fullRowIndex = (parentRowIndex ?? rowIndex) + this.pageSize * (this.page - 1);
    if (this.rowSelectionMode !== SimpleGridSelectionMode.None && this.toggleRowOnClicked) {
      this.toggleRowSelected(row);
    }
    this.cellClick.emit({ row, parentRow, rowIndex, parentRowIndex, fullRowIndex, column, columnIndex });
  }

  public adjustTooltipPosition(event: MouseEvent, el: HTMLElement): void {
    const hoveredColumn = (event.target as HTMLElement).closest('td')?.dataset.cname;
    const isTooltipExcludedFromColumn = this.tooltipExcludeColumns.includes(hoveredColumn ?? '');
    el.style.left = `${event.clientX + (isTooltipExcludedFromColumn ? 4000 : 4)}px`;
    el.style.top = `${event.clientY - (isTooltipExcludedFromColumn ? 4000 : 28)}px`;
  }

  /** Filters and sorts (but does not paginate) the given data source based on the filters selected in the grid. */
  public clientSideFilterSort<T = any>(data: T[] | null) {
    if (!data?.length) {
      return [];
    }

    // Filter
    for (const [, op] of this.filters) {
      data = this.applyFilter(data, op);
    }

    // Sort
    const allSorts = [
      ...(this.sortOn !== null ? [[this.sortOn, this.sortDir] as [string, SortDir]] : []),
      ...(this.fallbackSort ?? []),
    ];
    if (allSorts.length > 0) {
      data = orderBy(
        data,
        allSorts.map(([columnName]) => this.column(columnName)?.sortBy ?? columnName),
        allSorts.map(([, sortDir]) => sortDir),
      ) as T[];
    }

    return data;
  }

  /** Performs client side pagination on the given data, based on the pagination options of the grid. */
  public clientSidePaginate<T = any>(data: T[]) {
    return this.paginate
      ? data?.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
      : data;
  }

  public get hasHeaderFilters() {
    return this.columnData.some(c => typeof c?.headerFilter === 'string');
  }

  public get hasFilters() {
    return this.filters?.size > 0;
  }

  public get state() {
    return {
      filters: [...this.filters.values()],
      sort: [
        ...(this.sortOn !== null ? [[this.sortOn, this.sortDir] as [string, SortDir]] : []),
        ...(this.fallbackSort ?? []),
      ].map(([sortOn, sortDir]) => ({ sortOn, sortDir })),
      ...(this.paginate ? { page: this.page, pageSize: this.pageSize } : {}),
    } as GridStateModel;
  }

  public get canUserSetPageSize() {
    return this.pageSizeChoices.length > 0;
  }

  public alterPageSize(pageSizeSelected: string) {
    this.pageSize = +pageSizeSelected;
    this.updateData();
  }

  public get fullDatasource(): unknown {
    return this._gridFullDatasourceResult ?? [];
  }

  /** Fetches updated data from the datasource. Should be called when the data or state (such as filter) changes. */
  public async updateData() {
    if (this._datasource && this._isInitialised) {
      this.isLoading = true;
      this.loadingChange.emit(true);
      this._datasourceLatestResult = await this._datasource.getItems(this.state, this);

      this._gridFullDatasourceResult = [...this.clientSideFilterSort(this.allItems) ?? []];
      this.isLoading = false;
      this.loadingChange.emit(false);

      // not sure why but in some rare cases with array datasources it gets stuck showing loading until it redraws
      // manually tell the change detector that something's changed just in case
      this.cdr.markForCheck();
      this.constrainCurPage();
    } else {
      this._datasourceLatestResult = null;
      this._gridFullDatasourceResult = [];
      this.setPage(1, true);
    }
  }

  /** Gets a column by the given name, or null if one does not exist. */
  private column(name: string) {
    const column = this.columns.find(c => c.name === name);
    return column === undefined ? null : {
      // Defaults that may be overridden by column metadata
      ...defaultColumnOptions,
      label: this.startCasePipe.transform(column.name),
      headerFilter: this.defaultColumnHeaderFilterMode,
      dropdownFilter: this.defaultColumnDropdownFilterMode,
      sortable: this.defaultColumnSortable,
      render: column.type ? this.defaultRenderers[column.type] : null,
      template: this.inlineTemplates?.find(x => x.columnName === name)?.templateRef,
      classList: column.width ? 'overflow-auto' : '',

      // Column metadata
      ...column,

      // Computed styles that should override column metadata
      headerStyle: {
        ...(column.headerStyle ?? {}),
        ...(column.width ? { width: SimpleGridComponent.toCssWidth(column.width) } : {}),
      },
    } as ISimpleGridColumn;
  }

  public renderCell(row: any, columnName: string, target: SimpleGridColumnRendererTarget): string {
    const column = this.column(columnName);
    if (!column) {
      throw new Error(`Invalid column ${columnName} provided.`);
    }

    const value = typeof column.render === 'function'
      ? column.render(row[columnName], row, target, column.rendererOptions ?? {})
      : row[columnName]?.toString();

    // If the value is null/undefined, do not add prefix/suffix
    if (row[columnName] === null || row[columnName] === undefined) {
      return value;
    }

    const prefix = (typeof column.prefix === 'function' ? column.prefix(row[columnName]) : column.prefix) ?? '';
    const suffix = (typeof column.suffix === 'function' ? column.suffix(row[columnName]) : column.suffix) ?? '';
    return prefix + value + suffix;
  }

  private applyFilter(data: any[], operation: ISimpleGridFilterOperation): any[] {
    // If the column defines a custom filter handler, use that
    const column = this.column(operation.column);
    if (!column) {
      return data;
    }

    // Recursively filters an array. If the table supports children, then each item's children will also be searched for
    // a match.
    const filterWithChildren = (data: any[], f: (item: any) => boolean | undefined): any[] => {
      return data.filter(item =>
        f(item) || // check item itself
        (this.hasRowChildren && this.getChildRows(item)?.some(f)), // check item's children
      );
    };

    const customFilterHandler = column.filterHandler;
    if (typeof customFilterHandler === 'function') {
      return filterWithChildren(data, (row: any) => customFilterHandler(row[operation.column], operation, row));
    }

    // Attempt to coerce the type according to the column
    // For strings, convert to lower case so that we do case-insensitive comparison
    const coerceValue = (v: unknown) => {
      let value = SimpleGridComponent.columnTypedValue(v, column.type ?? SimpleGridColumnType.String);
      if ((column.type ?? SimpleGridColumnType.String) === SimpleGridColumnType.String) {
        value = value?.toString()?.toLowerCase();
      }
      return value;
    };

    switch (operation.op) {
      case SimpleGridFilterOperator.Equal: {
        const opValueTyped = coerceValue(operation.value);
        switch (column.type) {
          case SimpleGridColumnType.Date:
          case SimpleGridColumnType.DateTime:
            return filterWithChildren(data, row => isDateEqual(opValueTyped, coerceValue(row[operation.column])));
          default:
            return filterWithChildren(data, (row: any) => coerceValue(row[operation.column]) === opValueTyped);
        }
      }

      case SimpleGridFilterOperator.In: {
        if (!Array.isArray(operation.value)) {
          throw new Error('For \'in\' operator, the operation value must be an array.');
        }

        const coercedValues = operation.value.map(coerceValue);
        switch (column.type) {
          case SimpleGridColumnType.Date:
          case SimpleGridColumnType.DateTime:
            return filterWithChildren(data,
              row => coercedValues.some(v => isDateEqual(v, coerceValue(row[operation.column]))));
          default:
            return filterWithChildren(data, (row: any) => coercedValues.includes(coerceValue(row[operation.column])));
        }
      }

      case SimpleGridFilterOperator.Contains: {
        const opValueTyped = operation.value?.toString()?.toLowerCase();
        return filterWithChildren(data,
          (row: any) => row[operation.column]?.toString()?.toLowerCase()?.includes(opValueTyped));
      }

      case SimpleGridFilterOperator.GreaterThan: {
        const coercedValue = coerceValue(operation.value);
        switch (column.type) {
          case SimpleGridColumnType.Date:
          case SimpleGridColumnType.DateTime:
            return filterWithChildren(data, row => isDateBefore(coercedValue, coerceValue(row[operation.column])));
          default:
            return filterWithChildren(data, (row: any) => coerceValue(row[operation.column]) > coercedValue);
        }
      }

      case SimpleGridFilterOperator.GreaterThanOrEqual: {
        const coercedValue = coerceValue(operation.value);
        switch (column.type) {
          case SimpleGridColumnType.Date:
          case SimpleGridColumnType.DateTime:
            return filterWithChildren(data, row => {
              const rowValue = coerceValue(row[operation.column]);
              return isDateBefore(coercedValue, rowValue) || isDateEqual(coercedValue, rowValue);
            });
          default:
            return filterWithChildren(data, (row: any) => coerceValue(row[operation.column]) >= coercedValue);
        }
      }

      case SimpleGridFilterOperator.LessThan: {
        const coercedValue = coerceValue(operation.value);
        switch (column.type) {
          case SimpleGridColumnType.Date:
          case SimpleGridColumnType.DateTime:
            return filterWithChildren(data, row => isDateAfter(coercedValue, coerceValue(row[operation.column])));
          default:
            return filterWithChildren(data, (row: any) => coerceValue(row[operation.column]) < coercedValue);
        }
      }

      case SimpleGridFilterOperator.LessThanOrEqual: {
        const coercedValue = coerceValue(operation.value);
        switch (column.type) {
          case SimpleGridColumnType.Date:
          case SimpleGridColumnType.DateTime:
            return filterWithChildren(data, row => {
              const rowValue = coerceValue(row[operation.column]);
              return isDateAfter(coercedValue, rowValue) || isDateEqual(coercedValue, rowValue);
            });
          default:
            return filterWithChildren(data, (row: any) => coerceValue(row[operation.column]) <= coercedValue);
        }
      }

      case SimpleGridFilterOperator.Between: {
        if (!Array.isArray(operation.value) || operation.value.length !== 2) {
          throw new Error('For \'between\' operator, the operation value must be a pair of values.');
        }

        const coercedValues = operation.value.map(coerceValue);
        switch (column.type) {
          case SimpleGridColumnType.Date:
          case SimpleGridColumnType.DateTime:
            return filterWithChildren(data, row => {
              const rowValue = coerceValue(row[operation.column]);
              return isDateBefore(coercedValues[0], rowValue) || isDateEqual(coercedValues[0], rowValue)
                && (isDateAfter(coercedValues[1], rowValue) || isDateEqual(coercedValues[1], rowValue));
            });
          default:
            return filterWithChildren(data, (row: any) => {
              const rowValue = coerceValue(row[operation.column]);
              return rowValue >= coercedValues[0] && rowValue <= coercedValues[1];
            });
        }
      }

      default:
        throw new Error('Unknown operation type.');
    }
  }

  /** Attempts to correctly type a value according the type of a column. */
  private static columnTypedValue(value: unknown, columnType: SimpleGridColumnType): any | null {
    if (value === null || value === undefined) {
      return null;
    }

    switch (columnType) {
      case SimpleGridColumnType.String:
        return typeof value === 'string' ? value : (value as any)?.toString?.() ?? '';

      case SimpleGridColumnType.Number:
      case SimpleGridColumnType.Currency0:
      case SimpleGridColumnType.Currency2:
        const n = Number(value);
        return isNaN(n) ? 0 : n;

      case SimpleGridColumnType.Date:
        return SimpleGridComponent.tryParseDate(value, true);

      case SimpleGridColumnType.DateTime:
        return SimpleGridComponent.tryParseDateTime(value, true);

      case SimpleGridColumnType.Boolean:
        return value === true || value === 1 || value === '1' ||
             (typeof value === 'string' && value.toLowerCase() === 'true');
    }
  }

  /** Serializes a value for use within filter input controls. */
  public toInputValue(columnName: string, value: any) {
    if (value === null || value === undefined) {
      return '';
    }

    const colType = this.column(columnName)?.type ?? SimpleGridColumnType.String;

    switch (colType) {
      case this.columnType.Date:
        return formatDate(value, 'yyyy-MM-dd');
      case this.columnType.DateTime:
        return formatDate(value, 'yyyy-MM-dd\'T\'HH:mm');
      default:
        return value;
    }
  }

  public hasDropdownFilters(column: ISimpleGridColumn) {
    return column.dropdownFilter !== DropdownFilterType.None;
  }

  public listDropdownFilters(column: ISimpleGridColumn) {
    const filters: { label: string; value: SimpleGridFilterOperator }[] = [];
    const mode = column.dropdownFilter ?? DropdownFilterType.None;

    if (mode & DropdownFilterType.Equal) {
      filters.push({ label: 'Equal to', value: SimpleGridFilterOperator.Equal });
      filters.push({ label: 'Not equal to', value: SimpleGridFilterOperator.NotEqual });
    }

    // Not yet supported by UI
    /*if (mode & DropdownFilterType.In) {
      filters.push({ label: 'One of', value: SimpleGridFilterOperator.In });
    }*/

    if (mode & DropdownFilterType.Contains) {
      filters.push({ label: 'Contains text', value: SimpleGridFilterOperator.Contains });
    }

    if (mode & DropdownFilterType.Comparison) {
      switch (column.type) {
        case SimpleGridColumnType.Date:
        case SimpleGridColumnType.DateTime:
          filters.push(
            { label: 'Earlier than', value: SimpleGridFilterOperator.LessThan },
            { label: 'Earlier than or at', value: SimpleGridFilterOperator.LessThanOrEqual },
            { label: 'Later than', value: SimpleGridFilterOperator.GreaterThan },
            { label: 'Later than or at', value: SimpleGridFilterOperator.GreaterThanOrEqual },
            { label: 'Between', value: SimpleGridFilterOperator.Between },
          );
          break;

        default:
          filters.push(
            { label: 'Less than', value: SimpleGridFilterOperator.LessThan },
            { label: 'Less than or equal to', value: SimpleGridFilterOperator.LessThanOrEqual },
            { label: 'Greater than', value: SimpleGridFilterOperator.GreaterThan },
            { label: 'Greater than or equal to', value: SimpleGridFilterOperator.GreaterThanOrEqual },
            { label: 'Between', value: SimpleGridFilterOperator.Between },
          );
          break;
      }
    }

    return filters;
  }

  public getDiscreteValues(columnName: string, data: any[] | null = null): ISortOption[] | null {
    const column = this.column(columnName);
    if (!column) {
      return null;
    }
    // TODO: maybe allow data sources to provide distinct values instead of asking for all row items?
    const items = data ?? this._datasource?.allItems ?? this._datasourceLatestResult?.items ?? [];
    return orderBy(
      uniqBy(items, row => typeof row[columnName] === 'string' ? row[columnName].toLowerCase() : row[columnName])
        .map((row: any) => ({
          // If the row has a value for this column, determine the displayed dropdown label by rendering the cell
          label: row[columnName]?.toString()?.length ? this.renderCell(row, columnName, 'filter') : 'Blank',
          value: row[columnName],
          icon: column.discreteFilterIcon,
        })),
      'label') as ISortOption[];
  }

  public setSort(columnName: string, dir: SortDir, ignoreChanges = false) {
    const sortOnChanged = this.sortOn !== columnName;
    const sortDirChanged = this.sortDir !== dir;
    if (ignoreChanges || (sortOnChanged || sortDirChanged)) {
      this.sortOn = columnName;
      this.sortDir = dir;
      if (sortOnChanged) {
        this.sortOnChange.emit(this.sortOn);
      }
      if (sortDirChanged) {
        this.sortDirChange.emit(this.sortDir);
      }
      this.sortChange.emit([this.sortOn, this.sortDir]);
      this.updateData();
    }
  }

  public refreshSort(): void {
    if (this.sortOn && this.sortDir) {
      this.setSort(this.sortOn, this.sortDir, true);
    }
  }

  public isSortActive(columnName: string, dir?: SortDir) {
    return this.sortOn === columnName && (dir === undefined || this.sortDir === dir);
  }

  /** Sets the filter for the given column. */
  public setFilter(
    columnName: string,
    value: any,
    operator: SimpleGridFilterOperator = SimpleGridFilterOperator.Equal,
  ) {
    this.setFilterRaw({ column: columnName, op: operator,value });
  }

  /** Sets the filter for the given column. */
  public setFilterRaw(def: ISimpleGridFilterOperation) {
    const column = this.column(def.column);
    if (!column) {
      return;
    }

    // Don't set again if already set
    const existing = this.filters.get(def.column);
    if (!existing) {
      this.filters.set(def.column, def);
      this.filtersChange.emit({ status: SimpleGridFilterChangeType.Added, operation: def });
    } else if (existing.value !== def.value || existing.op !== def.op) {
      this.filters.set(def.column, def);
      this.filtersChange.emit({ status: SimpleGridFilterChangeType.Changed, operation: def });
    } else {
      return; // Do not updateExternalData if the filter is already set
    }
    this.updateData();
  }

  /** Determines if the given filter is active and filtering on the given value. */
  public isFilterActive(columnName: string, value?: any): boolean {
    const filter = this.filters.get(columnName);
    if (!filter) {
      return false;
    }

    switch (filter.op) {
      case SimpleGridFilterOperator.In: return filter.value === value || filter.value.includes(value);
      default: return filter.value === value;
    }
  }

  /** Returns whether any filters are active. */
  protected get anyFiltersActive() {
    return this.filters.size > 0;
  }

  /** Clears the filter from the given column. */
  public unsetFilter(columnName: string) {
    // Don't update if filter is already non-existent
    if (this.filters.has(columnName)) {
      const previous = this.filters.get(columnName);
      this.filters.delete(columnName);
      this.ifChanged('page', () => this.page = 1, v => this.pageChange.emit(v));
      this.filtersChange.emit({ status: SimpleGridFilterChangeType.Removed, operation: previous ?? null });
      this.updateData();
    }
  }

  public unsetAllFilters() {
    if (this.filters.size >= 1) {
      this.filters.clear();
      this.filtersChange.emit({ status: SimpleGridFilterChangeType.Cleared, operation: null });
      this.ifChanged('page', () => this.page = 1, v => this.pageChange.emit(v));
      this.updateData();
    }
  }

  public unsetLenderFilter() {
    this.filters.delete('lender');
    this.updateData();
  }

  public hasClearSortButton(columnName: string) {
    const columnData = this.columnData?.find(c => c?.name === columnName);
    if (columnData?.clearSortButton) {
      return this.sortOn === columnName;
    }

    return false;
  }

  public clearSort() {
    this.setSort(this.defaultSortOn ?? '', this.defaultSortDir ?? 'asc');
  }

  public updateSelectFilter(columnName: string, value: any[]) {
    if (!value.length) {
      this.unsetFilter(columnName);
      return;
    }

    if (this.column(columnName)?.type === SimpleGridColumnType.Number) {
      value = value.map(v => +v);
    }

    this.setFilter(
      columnName,
      value,
      SimpleGridFilterOperator.In);
  }

  public updateQuickFilter(
    columnName: string,
    value: any,
    source: 'input' | 'blur' | 'keydown',
    keyDownCode: number | null = null,
    ignoreRapidFiltering = false,
  ) {
    if (source === 'blur' ||
      (source === 'keydown' && keyDownCode === KeyCodes.Enter) ||
      ((this._datasource?.allowRapidFiltering ?? true) && !ignoreRapidFiltering)
    ) {
      const colType = this.column(columnName)?.type ?? SimpleGridColumnType.String;
      const typedValue = SimpleGridComponent.columnTypedValue(value, colType);

      if (typedValue !== undefined && typedValue?.length !== 0) {
        const op = colType === SimpleGridColumnType.String
          ? SimpleGridFilterOperator.Contains
          : SimpleGridFilterOperator.Equal;
        this.setFilter(columnName, typedValue, op);
      } else {
        this.unsetFilter(columnName);
      }
    }
  }

  public setDropdownFilterMode(columnName: string, op: SimpleGridFilterOperator | null) {
    if (op === null || (op as string) === '') {
      this.unsetFilter(columnName);
      return;
    }

    const isMultiValue = (op: SimpleGridFilterOperator) =>
      [SimpleGridFilterOperator.Between, SimpleGridFilterOperator.In].includes(op);

    // Check to see if there is an filtered applied. If it is a single-value filter type, and the user has selected
    // another single value filter type, keep the value.
    const existingFilter = this.filters.get(columnName);
    if (!isMultiValue(op) && existingFilter && !isMultiValue(existingFilter.op)) {
      this.setFilter(columnName, existingFilter.value, op);
    } else if (op === SimpleGridFilterOperator.Between) {
      this.setFilter(columnName, [null, null], op); // 'Between' expects exactly two
    } else {
      this.setFilter(columnName, isMultiValue(op) ? [] : null, op);
    }
  }

  public updateDropdownFilterValue(columnName: string, value: any, valueIndex?: number) {
    const filter = this.filters.get(columnName);
    if (!filter) {
      return;
    }

    const colType = this.column(columnName)?.type ?? SimpleGridColumnType.String;
    const typedValue = SimpleGridComponent.columnTypedValue(value, colType);

    if (valueIndex === undefined) {
      this.setFilter(columnName, typedValue, filter.op);
    } else {
      const values = [...filter.value];
      values[valueIndex] = typedValue;
      this.setFilter(columnName, values, filter.op);
    }
  }

  public selectDiscreteFilterOption(columnName: string, value: any) {
    const column = this.column(columnName);
    if (column?.discreteMultiSelect) {
      // If this column allows multiple selection values, then toggle the selection in the list
      const existing = this.filters.get(columnName);
      if (existing?.op === SimpleGridFilterOperator.In) {
        if (existing.value.includes(value)) {
          this.setFilter(columnName, existing.value.filter((x: unknown) => x !== value), SimpleGridFilterOperator.In);
        } else {
          this.setFilter(columnName, [...existing.value, value], SimpleGridFilterOperator.In);
        }
      } else {
        this.setFilter(columnName, [value], SimpleGridFilterOperator.In);
      }
    } else {
      this.setFilter(columnName, value, SimpleGridFilterOperator.Equal);
    }
  }

  public selectFilterPresetOption(columnName: string, value: ISimpleGridFilterPresetOption['value']) {
    if (typeof value === 'function') {
      this.setFilterRaw({ ...value(), column: columnName });
    } else {
      this.setFilterRaw({ ...value, column: columnName });
    }
  }

  public getSortText({ sortLabels, type }: ISimpleGridColumn, dir: SortDir) {
    if (sortLabels?.[dir]?.label) {
      return sortLabels[dir]?.label;
    }
    switch (type) {
      case SimpleGridColumnType.Number:
      case SimpleGridColumnType.Currency0:
      case SimpleGridColumnType.Currency2:
        return dir === 'asc' ? 'Low to High' : 'High to Low';
      case SimpleGridColumnType.Date:
      case SimpleGridColumnType.DateTime:
        return dir === 'asc' ? 'Earliest first' : 'Latest first';
      default:
        return dir === 'asc' ? 'A - Z' : 'Z - A';
    }
  }

  public getSortIcon({ sortLabels, type }: ISimpleGridColumn, dir: SortDir) {
    if (sortLabels?.[dir]) {
      return sortLabels[dir]?.icon ?? '';
    }
    switch (type) {
      case SimpleGridColumnType.String:
        return dir === 'asc' ? 'fa-sort-alpha-down' : 'fa-sort-alpha-up';
      case SimpleGridColumnType.Number:
      case SimpleGridColumnType.Date:
      case SimpleGridColumnType.DateTime:
      case SimpleGridColumnType.Currency0:
      case SimpleGridColumnType.Currency2:
        return dir === 'asc' ? 'fa-sort-numeric-up-alt' : 'fa-sort-numeric-down-alt';
      default:
        return dir === 'asc' ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-down';
    }
  }

  //#region Pagination
  public setPage(pageNum: number, suppressUpdate = false) {
    this.ifChanged('page', () => this.page = pageNum, v => this.pageChange.emit(v));
    if (!suppressUpdate) {
      this.updateData();
    }
  }

  /** Check to ensure that the currently selected page is not after the maximum page number. */
  public constrainCurPage() {
    const maxPages = Math.max(Math.ceil(this.totalItemCount / this.pageSize), 1);
    if (this.page > maxPages) {
      this.setPage(maxPages);
    }
  }
  //#endregion

  //#region Row selection
  /** Gets or sets the actual data rows that are selected in the grid.
   * If the datasource that is providing data does not support `allItems`, getting the rows is not supported.
   */
  @Input()
  public get selectedRows(): readonly any[] {
    const allItems = this._datasource?.allItems;
    return allItems?.length
      ? [...this._selectedRowKeys].map(key => allItems.find((item) => this.trackRowByFn(item) === key)).filter(Boolean)
      : [];
  }
  public set selectedRows(selectedRows) {
    this.selectedRowKeys = selectedRows.map(row => this.trackRowByFn(row));
  }

  /** Gets or sets the unique keys of the rows that are selected in the grid. */
  @Input()
  public get selectedRowKeys(): readonly any[] {
    return [...this._selectedRowKeys];
  }
  public set selectedRowKeys(selectedRowValues) {
    if (this.rowSelectionMode === SimpleGridSelectionMode.None) {
      throw Error('Cannot set the row selection for a grid that does not have row selection enabled.');
    } else if (this.rowSelectionMode === SimpleGridSelectionMode.Single && selectedRowValues?.length > 1) {
      throw Error('Cannot provide multiple selected rows for a grid that is in \'Single\' selection mode.');
    }

    this._selectedRowKeys.clear();
    if (selectedRowValues?.length) {
      for (const key of selectedRowValues) {
        this._selectedRowKeys.add(key);
      }
    }
  }

  public get showSelectionRadios() {
    return this.rowSelectionMode === SimpleGridSelectionMode.Single;
  }

  public get showSelectionCheckboxes() {
    return this.rowSelectionMode === SimpleGridSelectionMode.Multiple;
  }

  /** Returns `true` if all rows on the current page are selected. */
  public get isPageRowsSelected() {
    return this.pageData?.length
      && this.pageData.every(row => this.isRowSelected(row));
  }

  /** Returns `true` if some (but not all) rows on the current page are selected. */
  public get isPageRowsIndeterminate() {
    return this.pageData.some(row => this.isRowSelected(row))
      && this.pageData.some(row => !this.isRowSelected(row));
  }

  public isRowSelected(row: any) {
    // Note: check size first so that if selection is disabled we aren't trying to call trackRowByFn which may be null.
    return this._selectedRowKeys.size && this._selectedRowKeys.has(this.trackRowByFn(row));
  }

  public toggleRowSelected(row: any) {
    if (this.rowSelectionMode === SimpleGridSelectionMode.Single) {
      this._selectedRowKeys.clear();
    }
    const rowKey = this.trackRowByFn(row);
    this._selectedRowKeys[this._selectedRowKeys.has(rowKey) ? 'delete' : 'add'](rowKey);
    this.selectedRowsChange.emit(this.selectedRows);
  }

  public togglePageRowsSelected(pageRowsStatus: boolean) {
    for (const row of this.pageData) {
      const rowKey = this.trackRowByFn(row);
      this._selectedRowKeys[pageRowsStatus ? 'add' : 'delete'](rowKey);
    }
    this.selectedRowsChange.emit(this.selectedRows);
  }
  //#endregion

  //#region Custom filter menu dropdown
  public openFilter(column: ISimpleGridColumn, evtTarget: HTMLElement) {
    // closeFilter changes this.openFilterColumn, so this check must be first
    const isColumnOpen = column.name === this.openFilterColumn?.name;
    this.closeFilter();
    if (isColumnOpen) {
      return;
    } // If same filter button clicked again, only close it and don't reopen it
    this.openFilterColumn = column;
    this.filterMenuView = this.viewContainerRef.createEmbeddedView(this.filterDropdown, { $implicit: column });
    this.filterMenuView.detectChanges();
    this.filterMenuEl = this.filterMenuView.rootNodes[0] as HTMLElement;
    this.renderer.appendChild(this.document.body, this.filterMenuEl);
    setTimeout(() =>
      this.document.body.addEventListener('click', this.filterMenuEvt = this.bodyClickEvent.bind(this)), 0,
    );
    this.popper = createPopper(evtTarget, this.filterMenuEl, {
      placement: 'bottom',
      modifiers: [{
        name: 'preventOverflow',
        options: { boundary: this.tableContainer.nativeElement, tether: false },
      }, {
        name: 'offset',
        options: { offset: [0, -1] },
      }],
    });
  }

  public closeFilter() {
    if (this.filterMenuEl) {
      this.document.body.removeEventListener('click', this.filterMenuEvt);
      this.renderer.removeChild(this.document.body, this.filterMenuEl);
      this.filterMenuView?.destroy();
      this.popper?.destroy();
      this.filterMenuView = null;
      this.filterMenuEl = null;
      this.popper = null;
      this.openFilterColumn = null;
    }
  }

  public bodyClickEvent(e: MouseEvent) {
    if (this.shouldCloseMenu(e.target as HTMLElement)) {
      this.closeFilter();
    }
  }

  public shouldCloseMenu(el: HTMLElement) {
    while (el !== this.document.body) {
      // Always close if user clicks an item
      if (el.classList.contains('dropdown-item')) {
        return true;
      }

      // Don't close if click on the menu outside of one of the items (e.g. clicked on separator, padding, etc.)
      if (el.classList.contains('dropdown-menu')) {
        return false;
      }

      if (el.parentElement) {
        el = el.parentElement;
      }
    }
    return true;
  }
  //#endregion

  //#region Helpers
  public trackByIndex(index: number) {
    return index;
  }

  public trackByValue(_: number, item: { value: any }) {
    return item.value;
  }

  /** If the given property changes after the action is executed, executes the `ifChanged` action. */
  public ifChanged<K extends keyof SimpleGridComponent>(
    prop: K,
    action: () => void,
    onChanged: (val: this[K]) => void,
  ) {
    const prevValue = this[prop];
    action();
    const newValue = this[prop];
    if (prevValue !== newValue) {
      onChanged(newValue);
    }
  }

  private static toCssWidth(width: number | string) {
    return typeof width === 'number' ? `${width}px` : width;
  }

  /** Exports the data being displayed as CSV data.
   * The data in the CSV will be formatted using the `render` function defined on the column (if present), but custom
   * `sgCellTemplate`s cannot be used. CSV headers will be formatted as per their label. */
  public exportAsCsv({ excludeColumns, onlySelection }: { excludeColumns?: string[]; onlySelection?: boolean } = {}) {
    const columns = this.columnData.filter(x => !excludeColumns?.includes(x.name));
    const data = onlySelection ? (this.selectedRows as any[]) : this._datasource?.allItems;
    if (!data) {
      throw new Error('No rows selected or datasource does not provide direct access to all rows.');
    }
    return toCsv(
      data,
      columns.map(c => c.name),
      {
        headerFormatter: colName => this.column(colName)?.label ?? this.startCasePipe.transform(colName),
        cellFormatter: (_, column, row) => this.renderCell(row, column as string, 'cell'),
      },
    );
  }

  private static tryParseDate(value: unknown, allowIso: boolean): Date | undefined {
    const date = this.tryParseDateCore(value, allowIso, [
      'dd/MM/yyyy',
      'dd.MM.yyyy',
      'dd/MM/yy',
      'dd.MM/yy',
    ], false);

    // Though includeTime = false, we may still get the time when parsing from ISO for example, so clear it
    date?.setHours(0);
    date?.setMinutes(0);
    date?.setSeconds(0);
    date?.setMilliseconds(0);

    return date;
  }

  private static tryParseDateTime(value: unknown, allowIso: boolean): Date | undefined {
    return this.tryParseDateCore(value, allowIso, [
      'dd/MM/yyyy HH:mm',
      'dd.MM.yyyy HH:mm',
      'dd/MM/yy HH:mm',
      'dd.MM/yy HH:mm',

      'HH:mm dd/MM/yyyy',
      'HH:mm dd.MM.yyyy',
      'HH:mm dd/MM/yy',
      'HH:mm dd.MM/yy',

      'dd/MM/yyyy',
      'dd.MM.yyyy',
      'dd/MM/yy',
      'dd.MM/yy',
    ], true);
  }

  private static tryParseDateCore(
    value: unknown,
    allowIso: boolean,
    formats: string[],
    includeTime: boolean,
  ): Date | undefined {
    if (value === undefined || value === null) {
      return undefined;
    }
    if (isDate(value)) {
      return value as Date;
    }
    if (value instanceof NgbDate) {
      const ngbDate = value as NgbDate;
      return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    }
    if (typeof value === 'object' && 'date' in value) {
      const date = this.tryParseDateCore(value.date, allowIso, formats, false);
      if (isValidDate(date) && includeTime && 'time' in value && value.time && typeof value.time === 'object') {
        if ('hour' in value.time && typeof value.time.hour === 'number') {
          date?.setHours(value.time.hour);
        }
        if ('minute' in value.time && typeof value.time.minute === 'number') {
          date?.setMinutes(value.time.minute);
        }
        if ('second' in value.time && typeof value.time.second === 'number') {
          date?.setSeconds(value.time.second);
        }
      }
      if (isValidDate(date)) {
        return date;
      }
    }
    const valueStr: string = (value as string).toString();

    if (allowIso) {
      const isoDate = parseISODate(valueStr);
      if (isValidDate(isoDate)) {
        return isoDate;
      }
    }

    const refDate = includeTime ? new Date() : startOfDay(new Date()); // 00:00 on current day
    for (const format of formats) {
      const date = parseDate(valueStr, format, refDate);
      if (isValidDate(date)) {
        return date;
      }
    }

    return undefined;
  }
  //#endregion

  public shouldMergeHeaderRows(column: ISimpleGridColumn) {
    return typeof column.headerFilter !== 'string' && this.breakHeaderRow;
  }

  public closeExtraInfoBanner() {
    this.extraInfoHidden = true;
  }

  public getFilterIcon(icon: string) {
    // Do not add FAS class when icon classes have 'far'
    return icon?.includes('far')
      ? icon
      : `fas ${icon}`;
  }

  /**
   * Combines multiple class lists together. Classes provided in later parameters take precedence over those provided
   * earlier. E.G. `combineClassList(['a', 'b'], { b: false, c: true }) => { a: true, b: false, c: true }`
   */
  public combineClassList(...classLists: NgClass['ngClass'][]): NgClass['ngClass'] {
    // Combine into an object, as this is the most flexible implementation
    const combinedClassList = {};
    classLists.forEach(classList => {
      if (typeof classList === 'string') {
        combinedClassList[classList] = true;
      } else if (Array.isArray(classList) || classList instanceof Set) {
        classList.forEach((klass: string) => combinedClassList[klass] = true);
      } else if (!!classList) {
        Object.entries(classList).forEach(([klass, predicate]) => combinedClassList[klass] = predicate);
      }
    });
    return combinedClassList;
  }

  public executeMaybeCellAware<T>(value: T | CellAwareFunc<T>, row: any, column: ISimpleGridColumn): T {
    return typeof value === 'function' ? (value as CellAwareFunc<T>)(row[column.name], row, column) : value;
  }

  /** Gets attributes for a HTML `<input>` element based on the given grid data type. */
  public getInputAttributesFor(type: SimpleGridColumnType): Record<string, string> {
    switch (type) {
      case SimpleGridColumnType.Date:
        return { type: 'date' };
      case SimpleGridColumnType.DateTime:
        // eslint-disable-next-line spellcheck/spell-checker
        return { type: 'datetime-local' };
      case SimpleGridColumnType.Number:
        return { type: 'number', step: 'any' };
      case SimpleGridColumnType.Currency0:
        return { type: 'number', min: '0', step: '1' };
      case SimpleGridColumnType.Currency2:
        return { type: 'number', min: '0', step: '0.01' };
      default:
        return { type: 'text' };
    }
  }

  //#region Expandable child rows
  /** Whether or not the expand/collapse functionality is used on this grid. */
  protected get hasRowChildren(): boolean {
    return this.childRowSelector !== undefined;
  }

  /** Returns the child rows for the given row. */
  protected getChildRows(row: any): any[] {
    const selector = this.childRowSelector;
    let children: any = [];
    if (typeof selector === 'function') {
      children = selector(row);
    } else if (typeof selector === 'string') {
      children = row[selector];
    }
    return Array.isArray(children) ? children : [];
  }

  protected getFilteredSortedChildRows(row: any): any[] {
    return this.clientSideFilterSort(this.getChildRows(row));
  }

  /** Returns whether a specific row has any child rows or not. */
  protected hasChildRows(row: any): boolean {
    return this.getChildRows(row)?.length > 0;
  }

  /** Returns whether a specific row is expanded to show child rows. */
  protected isRowExpanded(row: any): boolean {
    const key = this.trackRowByFn?.(row);
    return this.expandedRows.has(key);
  }

  /** Toggles or forces whether a specific row is expanded to show child rows. */
  protected toggleRowExpanded(row: any, force?: boolean): void {
    const key = this.trackRowByFn?.(row);
    const isExpanded = typeof force === 'boolean' ? force : !this.expandedRows.has(key);
    this.expandedRows[isExpanded ? 'add' : 'delete'](key);
  }
  //#endregion
}
