import { Injectable } from '@angular/core';
import { NavMessageKeys, NavPosition, PageNavMessage } from '@msslib/models';
import { AffordabilityCallbackRequestData, AffordabilityRequest, BdgAffordabilityRequest,
  BtlAffordabilityRequest, IgnitePlusAffordabilityCallbackRequest,
  IgnitePlusAffordabilityResult } from 'apps/shared/src/models';
import { of, tap } from 'rxjs';
import { CaseData, SsoCallBackEventType } from '../models';
import { AuthorizeService, ClubHubDataService, ModalService, PageNavMessageService } from '@msslib/services';
import { IgniteCommonDataService } from './ignite-common-data.service';
import { CaseLinkModalComponent } from '../components/case-link-modal/case-link-modal.component';

@Injectable({
  providedIn: 'root',
})
export class S365Service {

  public s365Data: CaseData | null = null;

  public constructor(
    private clubHubDataService: ClubHubDataService,
    private pageNavMessageService: PageNavMessageService,
    private authService: AuthorizeService,
    private igniteCommonDataService: IgniteCommonDataService,
    private modalService: ModalService,
  ) {}

  public dataReset() {
    this.modalService.open({
      component: CaseLinkModalComponent,
      size: 'md',
      hideHeader: true,
      sticky: true,
    });
  }

  public removeData() {
    return this.clubHubDataService.delete('affordability/clearPrePopFormValues')
      .pipe(tap(() => {
        this.s365Data = null;
        this.pageNavMessageService.removeFromMessages(NavMessageKeys.CaseLink);
      }));
  }

  public getData() {
    if (this.s365Data) {
      return of(this.s365Data);
    }

    return this.clubHubDataService
      .get<CaseData>('affordability/retrievePrePopFormValues')
      .pipe(tap((data) => {
        if (data) {
          this.s365Data = data;
          this.pageNavMessageService.pushToMessages(this.navigationMessageCaseLink);
        }
      }));
  }

  public get navigationMessageCaseLink(): PageNavMessage {
    const clientDisplayName = this.s365Data?.clientDetails?.forename && this.s365Data?.clientDetails?.surname
      ? ` - ${this.s365Data.clientDetails?.forename} ${this.s365Data?.clientDetails?.surname}`
      : '';
    const navMsg = this.s365Data?.sourcingCallBack ?
      `Linked to case ${this.s365Data.reference}${clientDisplayName} in ${this.authService.user?.client_id}` :
      `Using case ${this.s365Data?.reference}${clientDisplayName} to auto populate forms`;
    return {
      key: NavMessageKeys.CaseLink,
      navItem: {
        textWithoutLink: navMsg,
      },
      position: NavPosition.Left,
    };
  }

  public mapSmartr365CallbackRequest(affordabilityModelRequest: AffordabilityRequest | BtlAffordabilityRequest
    | BdgAffordabilityRequest | null): IgnitePlusAffordabilityCallbackRequest {
    return {
      data: {
        affordabilityRequest: affordabilityModelRequest,
        results: this.igniteCommonDataService.results?.quotes?.map(quote => {
          return {
            lenderName: quote.lenderName,
            maximumValue: quote.maximumAffordableLoanAmount,
            error: quote.error?.message ?? quote.httpErrorMessage,
          } as IgnitePlusAffordabilityResult;
        }),
      } as AffordabilityCallbackRequestData,
      events: this.s365Data?.events?.filter(x => x.eventType === SsoCallBackEventType.Affordability),
      clientDetails: this.s365Data?.clientDetails,
      organisationId: this.s365Data?.organisationId,
      reference: this.s365Data?.reference,
    } as IgnitePlusAffordabilityCallbackRequest;
  }
}
