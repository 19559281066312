import { Inject, Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { FilterKey, IIgniteFilterOption } from 'apps/clubhub/src/app/ignite/models/affordability';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { OutcomeResults } from '@msslib/models/enums/outcome-results';
import { IgniteService } from 'apps/clubhub/src/app/ignite/services';
import { SortOrder } from '@msslib/models/filter-sort';
import { IgniteResponse } from 'apps/clubhub/src/app/ignite/models';
import { YesNoResults } from '@msslib/models';

@Injectable({
  providedIn: 'root',
})
export class ResultsFilterService {
  public constructor(
    @Inject(IgniteService) private igniteService,
    private analyticsService: AnalyticsService,
  ) {
  }

  private filter: IIgniteFilterOption = {};

  public get filters(): Readonly<IIgniteFilterOption> {
    return this.filter;
  }

  private get hasFilter(): boolean {
    return !!(this.filter.lenderName ??
      this.filter.outcomeResult ??
      this.filter.igniteResultsOrder ??
      this.filter.propertyResult ??
      this.filter.productsMatched
    );
  }

  public resetFilter(): void {
    this.filter = {};
  }

  public onFilterChange(key: FilterKey , value: unknown): void {
    this.filter = { ...this.filter, [key]: value };
    let filterType: string | undefined = undefined;
    let details: string | undefined = undefined;

    if (key === FilterKey.ProductsMatched) {
      filterType = 'Products';
      if (value === YesNoResults.Yes) {
        details = 'Yes';
      } else if (value === YesNoResults.No) {
        details = 'No';
      } else {
        details = 'Unset';
      }

      return;
    }

    if (key === FilterKey.OutcomeResult || key === FilterKey.PropertyResult) {
      switch (key) {
        case FilterKey.OutcomeResult:
          filterType = 'Criteria';
          break;
        case FilterKey.PropertyResult:
          filterType = 'Property';
          break;
      }

      if (value === OutcomeResults.Yes) {
        details = 'Yes';
      } else if (value === OutcomeResults.Refer) {
        details = 'Refer';
      } else if (value === OutcomeResults.No) {
        details = 'No';
      } else if (value === OutcomeResults.Unset) {
        details = 'Restricted';
      } else {
        details = 'Unset';
      }
    }

    if (details !== undefined) {
      this.analyticsService.trackDropdown(`Select ${filterType} Result Type: ${details}`, details);
    }
  }

  public filterResults(results: IgniteResponse[] | undefined): IgniteResponse[] | undefined {
    if (!this.hasFilter || !results?.length) {
      return this.igniteService.getResults();
    }

    const {
      lenderName,
      igniteResultsOrder,
      outcomeResult,
      propertyResult,
      productsMatched,
    } = this.filter;

    if (lenderName) {
      results = lenderName.length ? results.filter((o: IgniteResponse) => {
        return lenderName.some(x => x.toUpperCase() === o.lenderName.toUpperCase());
      }) : results;
    }

    if (outcomeResult) {
      results = results.filter(
        (result: IgniteResponse) =>
          this.igniteService.getCriteriaFilterResult(result) ===
          (outcomeResult === OutcomeResults.Unset ? undefined : outcomeResult),
      );
    }

    if (propertyResult) {
      results = results.filter(
        (result: IgniteResponse) =>
          this.igniteService.getPropertyFilterResult(result) ===
          (propertyResult === OutcomeResults.Unset ? undefined : propertyResult),
      );
    }

    if (productsMatched) {
      let resultsWithLenderProducts = results.map(result => {
        const lenderProducts = this.igniteService.getLenderMatchedProducts(result);
        return {
          lenderProducts: lenderProducts,
          ...result,
        };
      }).filter(result => {
        return productsMatched === YesNoResults.Yes
          ? result.lenderProducts?.products?.length
          : (!result.lenderProducts?.products?.length);
      });

      if (productsMatched === YesNoResults.No) {
        resultsWithLenderProducts = resultsWithLenderProducts.sort(r => !r.lenderProducts ? 1 : -1);
      }

      results = resultsWithLenderProducts;
    }

    if (igniteResultsOrder) {
      results = orderBy(
        results,
        // The fallback value for max value depends on the sort order, this way results with no data always appear last
        (o: IgniteResponse) => o.maximumAffordableLoanAmount
          ?? (igniteResultsOrder === SortOrder.Ascending ? Number.MAX_VALUE : -1),
        igniteResultsOrder === SortOrder.Ascending ? 'asc' : 'desc',
      );
    }

    return results;
  }
}
