export enum ProductSourcingConditionType {
  RepaymentTypes = 0,
  ExpatNotInUk = 1,
  FirstTimeLandlord = 2,
  GreenEco = 3,
  Hmo = 4,
  HolidayLet = 5,
  JointBorrowerSoleProprietor = 6,
  LimitedCompanyPurchase = 7,
  // eslint-disable-next-line spellcheck/spell-checker
  Mufb = 8,
  NewBuild = 9,
  Offset = 10,
  OwnerOccupier = 11,
  PortfolioLandlord = 12,
  RegulatedBuyToLet = 13,
  RetirementInterestOnly = 14,
  RightToBuy = 15,
  SecondResidential = 16,
  SelfBuild = 17,
  SharedEquity = 18,
  SharedOwnership = 19,
}

export enum ProductSourcingRuleMode {
  Default = 0,
  OnlAvailable = 1,
}
