import { EventEmitter, Inject, Injectable } from '@angular/core';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import {
  BridgingProduct,
  LendingTypeCode,
  MatchedLenderProducts,
  MiProductsFiltersViewModel,
  Product,
} from 'apps/shared/src/models';
import {
  FilterOptionsContext,
  ProductFilterDefinition,
  ProductFilterGroupDefinition,
  ProductsFilterContext,
  ProductsFilterHelper,
} from './products-filter/products-filter.helper';

export interface ProductFilter {
  title: string;
  checked: boolean;
  included: boolean;
  excluded: boolean;
  isCriteriaFilter: boolean;
  subFilters?: {
    items: ProductFilterDefinition<Product | BridgingProduct>[];
    checkedItems: Set<string>;
  };
  hasSubFilters?: boolean;
  hasNumericValue?: boolean;
  numericValue?: number;
  hasRangeValues?: boolean;
  rangeFilterUnits?: string;
  fromValue?: number;
  toValue?: number;
  validationErrors?: string;
}

export type ProductFilterGroup = {
  title: string;
  items: readonly ProductFilter[];
  disabled: boolean;
  isIncludeExcludeFilter?: boolean;
  groupInfoText?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ProductsFilterService {

  public filterChanged = new EventEmitter<boolean>();
  private checkedFilters: Map<string, Set<string>>;
  private includeExcludeGroupsFilters: Map<string, any> = new Map<string, unknown>;
  private numericValueFilters: Map<string, number | undefined> = new Map<string, number | undefined>;
  private rangeValueFilters: Map<string, [number | undefined, number | undefined]> =
    new Map<string, [number | undefined, number | undefined]>;
  private availableFiltersCache = new Map<string, boolean | undefined>();
  private isPristine = true;

  private productFilterDefinitions: ProductFilterGroupDefinition<Product | BridgingProduct>[];
  private isBridgingType: boolean;
  public productsFilterContext: ProductsFilterContext | undefined;

  public constructor(
    @Inject(LendingTypeService) private lendingTypeService,
    private productsFilterHelper: ProductsFilterHelper,
  ) {
    this.setProductFilterDefinitions();
  }

  public setProductFilterDefinitions(): void {
    const lendingTypeCode = this.lendingTypeService.lendingType?.code?.replace(/ /g, '').toLowerCase();
    this.isBridgingType = lendingTypeCode === LendingTypeCode.Bdg.toLowerCase();

    if (this.isBridgingType) {
      this.productFilterDefinitions = this.productsFilterHelper.bridgingProductFilterDefinitions;
    } else {
      this.productFilterDefinitions =
        this.productsFilterHelper.resAndBtlProductFilterDefinitions();
    }

    this.checkedFilters = new Map(this.productFilterDefinitions.map(g => [g.title, new Set()]));
  }

  private getIncludeExcludeFilterContexts() {
    if (this.includeExcludeGroupsFilters?.size > 0) {
      const filtersContexts = [...this.includeExcludeGroupsFilters.keys()]
        .map(key => {
          const group = this.productFilterDefinitions.find(i => i.title === key);
          const includeExcludeGroupFilters = this.includeExcludeGroupsFilters.get(key);
          return {
            group,
            includedItems: [...includeExcludeGroupFilters.includedFilterDefs.keys()],
            excludedItems: [...includeExcludeGroupFilters.excludedFilterDefs.keys()],
          };
        });
      return filtersContexts;
    }

    return [];
  }

  /** Gets all 'active' filters, including those that have been overwritten to be active */
  private getActiveFilters(ctx: FilterOptionsContext<Product | BridgingProduct>, includeOverridden: boolean) {
    return this.productFilterDefinitions
      .map(group => ({
        group,
        items: 'items' in group
          ? group.items
            .filter(i =>
              (includeOverridden ? i.overrideChecked?.(ctx) : null)
              ?? this.checkedFilters.get(group.title)?.has(i.title))
            .map(i => i.title)
          : this.checkedFilters.get(group.title)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ? [...this.checkedFilters.get(group.title)!]
            : [],
      }));
  }

  public hasActiveIncludeFilter(group: string, name: string): boolean {
    return this.includeExcludeGroupsFilters.get(group)?.includedFilterDefs?.has(name);
  }

  /** Gets an object for the backend to replicate the selected filters. */
  public getSelectedFilterNames(context: FilterOptionsContext<Product | BridgingProduct>): Record<string, string[]> {
    context ??= {} as any;
    const obj = {};
    this.getActiveFilters(context, true).forEach(({ group, items }) => {
      obj[group.requestObjKey] = [...(obj[group.requestObjKey] ?? []), ...items];
    });

    const appliedNumericValueFilters = this.getAllNumericValueFilterKeys();
    appliedNumericValueFilters.forEach(filterTitle => {
      const group = this.getGroupByFilterTitle(filterTitle);
      if (group && obj[group.requestObjKey]) {
        obj[group.requestObjKey].push(filterTitle);
      }
    });

    const appliedRangeFilters = this.getAllRangeFilterKeys();
    appliedRangeFilters.forEach(filterTitle => {
      const group = this.getGroupByFilterTitle(filterTitle);
      if (group && obj[group.requestObjKey]) {
        obj[group.requestObjKey].push(filterTitle);
      }
    });

    const filterOptions = this.getFilterOptions(context);
    const includeExcludeGroupsFilter = this.getIncludeExcludeFilterContexts();
    includeExcludeGroupsFilter.forEach(({group, includedItems, excludedItems}) => {
      if (!group) {
        return;
      }

      const includedFieldName = `${group.requestObjKey}Included`;
      const excludedFieldName = `${group.requestObjKey}Excluded`;

      const groupItems = filterOptions.find(g => g.title === group.title)?.items ?? [];
      const getIncludedExcludedFilters = (items) => {
        return items.map(itemTitle => {
          const filterWithSubFilters = groupItems.find(i => i.title === itemTitle);
          if (!filterWithSubFilters) {
            return null;
          }

          if (filterWithSubFilters.hasSubFilters) {
            return {
              title: itemTitle,
              hasSubFilters: true,
              checkedSubItems: [...(filterWithSubFilters.subFilters?.checkedItems ?? [])],
            };
          }

          return {
            title: itemTitle,
            hasSubFilters: false,
          };
        }).filter(f => !!f);
      };

      const includedFilters = getIncludedExcludedFilters(includedItems);
      const excludedFilters = getIncludedExcludedFilters(excludedItems);

      obj[includedFieldName] = [...(obj[includedFieldName] ?? []), ...includedFilters];
      obj[excludedFieldName] = [...(obj[excludedFieldName] ?? []), ...excludedFilters];
    });

    const rangeFilters: {name: string; from: number | undefined; to:number | undefined}[] = [];
    for (const item of [...this.rangeValueFilters]) {
      const [
        key,
        value,
      ] = item;
      rangeFilters.push({
        name: key,
        from: value[0],
        to: value[1],
      });
    }
    obj['rangeFilters'] = rangeFilters;

    return obj;
  }

  public getFilterOptions(ctx: FilterOptionsContext<Product | BridgingProduct>): ProductFilterGroup[] {
    ctx ??= {} as any;
    return this.productFilterDefinitions.map(group => {
      const isIncludeExcludeFilter = group.isIncludeExcludeFilter;
      const groupFilters = isIncludeExcludeFilter
        ? this.includeExcludeGroupsFilters.get(group.title)
        : null;

      return {
        title: group.title,
        isIncludeExcludeFilter: isIncludeExcludeFilter,
        hasSubFilters: group.hasSubFilters,
        groupInfoText: group.groupInfoText,
        items: ('itemFactory' in group ? group.itemFactory(ctx) : group.items)
          .filter(item =>
            (item.visible?.(ctx) ?? true)
            && this.isFilterAvailable(group, item, ctx),
          )
          .map(filter => {
            const includedFilterDef = groupFilters?.includedFilterDefs?.get(filter.title);
            const excludedFilterDef = groupFilters?.excludedFilterDefs?.get(filter.title);

            const result = {
              title: filter.title,
              checked: filter.overrideChecked?.(ctx) ?? this.checkedFilters.get(group.title)?.has(filter.title),
              included: !!includedFilterDef,
              excluded: !!excludedFilterDef,
              isCriteriaFilter: filter.isCriteriaFilter ?? false,
              hasSubFilters: filter.hasSubFilters,
              numericFilterDescription: filter.numericFilterDescription,
              numericFilterPrefix: filter.numericFilterPrefix,
              hasNumericValue: filter.hasNumericValue,
              hasRangeValues: filter.isRangeFilter,
              rangeFilterUnits: filter.rangeFilterUnits,
              fromValue: filter.isRangeFilter ? this.getRangeFilterValue(filter.title)?.[0] : undefined,
              toValue: filter.isRangeFilter ? this.getRangeFilterValue(filter.title)?.[1] : undefined,
              numericValue: filter.hasNumericValue ? this.getNumericFilterValue(filter.title) : undefined,
              subFilters: {
                items: filter.hasSubFilters && filter.itemFactory ? filter.itemFactory(ctx) : [],
                checkedItems: (includedFilterDef || excludedFilterDef)?.checkedSubFilters || new Set<string>(),
              },
            } as ProductFilter;
            return result;
          }),
        disabled: group.disabled?.(ctx) ?? false,
      } as ProductFilterGroup;
    });
  }

  public get currentAppliedFilters() {
    const checkedFilters = [...this.checkedFilters].map(filterGroup => {
      // filterGroup -> [ "filterGroupName", <set of applied filters>]
      const checkedFilters = [...filterGroup[1].values()];
      return checkedFilters.map(checkedFilter => ({
        filterGroup: filterGroup[0],
        filterName: checkedFilter,
        isChecked: true,
        isIncluded: false,
        isExcluded: false,
        checkedSubFilters: [],
      } as MiProductsFiltersViewModel));
    }).flat();

    const includedExcludedFilters = [...this.includeExcludeGroupsFilters].map(groupKeyValue => {
      const groupName = groupKeyValue[0];
      const groupFilters = groupKeyValue[1];

      const excludedFilters = [... groupFilters.excludedFilterDefs].map(excFilterDef => {
        const excludeFilterName = excFilterDef[0];
        const excludeFilterValue = excFilterDef[1];
        let excludeCheckedSubFilters: string[] = [];
        if (excludeFilterValue.checkedSubFilters?.size) {
          excludeCheckedSubFilters = [...excludeFilterValue.checkedSubFilters.values()];
        }

        return {
          filterGroup: groupName,
          filterName: excludeFilterName,
          isExcluded: true,
          isIncluded: false,
          isChecked: false,
          checkedSubFilters: excludeCheckedSubFilters,
        } as MiProductsFiltersViewModel;
      });

      const includedFilters = [...groupFilters.includedFilterDefs].map(includeFilterKeyValue => {
        const incFilterName = includeFilterKeyValue[0];
        const incFilterValue = includeFilterKeyValue[1];

        let checkedSubFilters: string[] = [];
        if (incFilterValue.checkedSubFilters?.size) {
          checkedSubFilters = [...incFilterValue.checkedSubFilters.values()];
        }

        return {
          filterGroup: groupName,
          filterName: incFilterName,
          isIncluded: true,
          isExcluded: false,
          isChecked: false,
          checkedSubFilters: checkedSubFilters,
        } as MiProductsFiltersViewModel;
      });

      return includedFilters.concat(excludedFilters);
    }).flat();

    const numericValueFilters = this.getAllNumericValueFilterKeys()
      .map(filterKey => {
        return {
          filterGroup: this.getGroupTitleByFilterTitle(filterKey),
          filterName: filterKey,
          isIncluded: false,
          isExcluded: false,
          isChecked: true,
          checkedSubFilters: [],
          hasNumericValue: true,
          numericValue: this.numericValueFilters.get(filterKey),
        } as MiProductsFiltersViewModel;
      });

    const rangeFilters = this.getAllRangeFilterKeys()
      .map(filterKey => {
        return {
          filterGroup: this.getGroupTitleByFilterTitle(filterKey),
          filterName: filterKey,
          isIncluded: false,
          isExcluded: false,
          isChecked: true,
          checkedSubFilters: [],
          hasNumericValue: false,
          numericValue: 0,
          fromValue: this.rangeValueFilters.get(filterKey)?.[0],
          toValue: this.rangeValueFilters.get(filterKey)?.[1],
          hasRangeValue: true,
        } as MiProductsFiltersViewModel;
      });

    return checkedFilters.concat(includedExcludedFilters).concat(numericValueFilters).concat(rangeFilters);
  }

  public applySavedFilters(savedFilters: MiProductsFiltersViewModel[]) {
    if (!savedFilters?.length) {
      return;
    }

    this.clearFilters();

    const excludeIncludeFilters = savedFilters.filter(f => f.isIncluded || f.isExcluded);
    for (let i = 0; i < excludeIncludeFilters.length; i++) {
      const filter = excludeIncludeFilters[i];
      this.setIncludeExcludeFilter(
        filter.filterGroup,
        filter.filterName,
        filter.isIncluded,
        filter.isExcluded,
        true,
        filter.checkedSubFilters,
      );
    }

    const checkedFilters = savedFilters.filter(f => f.isChecked);
    for (let i = 0; i < checkedFilters.length; i++) {
      const filter = checkedFilters[i];
      if (filter.hasNumericValue) {
        if (filter.numericValue) {
          this.setNumericValueFilter(
            {
              title: filter.filterName,
              checked: filter.isChecked,
              hasNumericValue: filter.hasNumericValue,
              numericValue: filter.numericValue,
            } as ProductFilter,
            filter.isChecked,
            true,
          );
        }
      } else if (filter.hasRangeValue) {
        if (filter.fromValue || filter.toValue) {
          this.setRangeValueFilter(
            {
              title: filter.filterName,
              checked: filter.isChecked,
              hasRangeValues: filter.hasRangeValue,
              fromValue: filter.fromValue,
              toValue: filter.toValue,
            } as ProductFilter,
            filter.isChecked,
            true,
          );
        }
      } else {
        this.setFilter(filter.filterGroup, filter.filterName, filter.isChecked, true);
      }
    }
    this.filterChanged.emit();
  }

  public setIncludeExcludeFilter(
    groupName: string,
    filterName: string,
    includeChecked: boolean,
    excludeChecked: boolean,
    bulkUpdate = false,
    subFilters: string[] = [],
  ) {
    if (!this.includeExcludeGroupsFilters.has(groupName)) {
      this.includeExcludeGroupsFilters.set(
        groupName,
        {
          includedFilterDefs: new Map<string, ProductFilterDefinition<Product | BridgingProduct>>(),
          excludedFilterDefs: new Map<string, ProductFilterDefinition<Product | BridgingProduct>>(),
        },
      );
    }

    this.isPristine = false;

    const groupFilters = this.includeExcludeGroupsFilters.get(groupName);
    if (!includeChecked && !excludeChecked) {
      if (groupFilters.includedFilterDefs.has(filterName)) {
        groupFilters.includedFilterDefs.delete(filterName);
      }

      if (groupFilters.excludedFilterDefs.has(filterName)) {
        groupFilters.excludedFilterDefs.delete(filterName);
      }

      if (!bulkUpdate) {
        this.filterChanged.emit();
      }
      return;
    }

    const groupFilterDef = this.productFilterDefinitions.find(p => p.title === groupName);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const itemsFiltersDefs = groupFilterDef?.['items'];
    const filterDef = itemsFiltersDefs.find(i => i.title === filterName);

    if (!filterDef.isMatchFunc && !filterDef.hasSubFilters) {
      if (!bulkUpdate) {
        this.filterChanged.emit();
      }
      return;
    }

    if (includeChecked) {
      if (groupFilters.excludedFilterDefs.has(filterName)) {
        groupFilters.excludedFilterDefs.delete(filterName);
      }
      if (!groupFilters.includedFilterDefs.has(filterName)) {
        groupFilters.includedFilterDefs.set(filterName, {
          title: filterDef.title,
          isMatchFunc: filterDef.isMatchFunc,
          isUnknownFunc: filterDef.isUnknownFunc,
          hasSubFilters: filterDef.hasSubFilters,
          itemFactory: filterDef.itemFactory,
          isMatchFuncFactory: filterDef.isMatchFuncFactory,
          checkedSubFilters: new Set<string>(subFilters ?? []),
        });
      }
    } else {
      if (groupFilters.includedFilterDefs.has(filterName)) {
        groupFilters.includedFilterDefs.delete(filterName);
      }
      if (!groupFilters.excludedFilterDefs.has(filterName)) {
        groupFilters.excludedFilterDefs.set(filterName, {
          title: filterDef.title,
          isMatchFunc: filterDef.isMatchFunc,
          isUnknownFunc: filterDef.isUnknownFunc,
          isExcludeFunc: filterDef.isExcludeFunc,
          hasSubFilters: filterDef.hasSubFilters,
          itemFactory: filterDef.itemFactory,
          isMatchFuncFactory: filterDef.isMatchFuncFactory,
          checkedSubFilters: new Set<string>(),
        });
      }
    }

    if (!bulkUpdate) {
      this.filterChanged.emit();
    }
  }

  public isNumericFilterChecked(productTitle: string): boolean {
    return this.numericValueFilters.has(productTitle);
  }

  public isRangeFilterChecked(productTitle: string): boolean {
    return this.rangeValueFilters.has(productTitle);
  }

  public getNumericFilterValue(productTitle: string): number | undefined {
    if (this.isNumericFilterChecked(productTitle)) {
      return this.numericValueFilters.get(productTitle);
    }
  }

  public getRangeFilterValue(productTitle: string) {
    if (this.isRangeFilterChecked(productTitle)) {
      return this.rangeValueFilters.get(productTitle);
    }
  }

  public setNumericValueFilter(
    productFilter: ProductFilter,
    isChecked: boolean,
    bulkUpdate = false,
  ) {
    const hasFilter = this.numericValueFilters.has(productFilter.title);
    if (hasFilter) {
      this.numericValueFilters.delete(productFilter.title);
    }

    if (isChecked) {
      this.numericValueFilters.set(
        productFilter.title,
        productFilter.numericValue,
      );
    }

    if (!bulkUpdate) {
      this.filterChanged.emit();
    }
  }

  public setRangeValueFilter(
    productFilter: ProductFilter,
    isChecked: boolean,
    bulkUpdate = false,
  ) {
    const hasFilter = this.rangeValueFilters.has(productFilter.title);
    if (hasFilter) {
      this.rangeValueFilters.delete(productFilter.title);
    }

    if (isChecked) {
      this.rangeValueFilters.set(
        productFilter.title,
        [productFilter.fromValue, productFilter.toValue],
      );
    }

    if (!bulkUpdate) {
      this.filterChanged.emit();
    }
  }

  public setRangeFilters(title: string, fromValue: number, toValue: number) {
    this.rangeValueFilters.set( title, [fromValue, toValue]);
    this.filterChanged.emit();
  }

  public getFilterDefinitionByTitle(filterTitle: string) {
    return this.productFilterDefinitions.map(g => {
      const items = g['items'];
      if (items) {
        const filterDef = items.find(i => i.title === filterTitle);
        return filterDef;
      }
    }).find(f => !!f);
  }

  private getNumericValueFilterFunc(
    filterName: string,
  ): ((product: Product) => boolean) | null {
    const numericValue = this.numericValueFilters.get(filterName);
    if (!numericValue) {
      return null;
    }

    const numericValueFilterFunc = (product: Product) => {
      const filterDef = this.getFilterDefinitionByTitle(filterName);

      const isMatched = !!filterDef?.isMatchFunc
        ? filterDef.isMatchFunc(product, numericValue)
        : true;

      return isMatched;
    };

    return numericValueFilterFunc;
  }

  private getRangeFilterFunc(
    filterName: string,
  ): ((product: Product) => boolean) | null {
    const rangeValues = this.rangeValueFilters.get(filterName);
    if (!rangeValues) {
      return null;
    }

    const rangeValueFilterFunc = (product: Product) => {
      const filterDef = this.getFilterDefinitionByTitle(filterName);

      const isMatched = !!filterDef?.isMatchFunc
        ? filterDef.isMatchFunc(product, rangeValues[0], rangeValues[1])
        : true;

      return isMatched;
    };

    return rangeValueFilterFunc;
  }

  private getAllNumericValueFilters() {
    const numericValueFilters = this.getAllNumericValueFilterKeys()
      .map(key => {
        const filterFunc = this.getNumericValueFilterFunc(key);
        if (filterFunc) {
          return filterFunc;
        }
      }).filter(r => !!r) as ((product: Product) => boolean)[];

    return numericValueFilters;
  }

  private getAllRangeFilters() {
    const rangeFilters = this.getAllRangeFilterKeys()
      .map(key => {
        const filterFunc = this.getRangeFilterFunc(key);
        if (filterFunc) {
          return filterFunc;
        }
      }).filter(r => !!r) as ((product: Product) => boolean)[];

    return rangeFilters;
  }

  private getAllNumericValueFilterKeys() {
    if (this.numericValueFilters?.size > 0) {
      return [...this.numericValueFilters.keys()];
    }

    return [];
  }

  private getAllRangeFilterKeys() {
    if (this.rangeValueFilters?.size > 0) {
      return [...this.rangeValueFilters.keys()];
    }

    return [];
  }

  private getGroupByFilterTitle(filterTitle: string) {
    const group = this.productFilterDefinitions.find(g => {
      const items = g['items'];
      if (items) {
        return items.some(i => i.title === filterTitle);
      }
    });

    return group;
  }

  private getGroupTitleByFilterTitle(filterTitle: string) {
    return this.getGroupByFilterTitle(filterTitle)?.title;
  }

  private getIncludeExcludeFilter(
    groupName: string | undefined,
    includeUnknown = false,
    matchedProducts: MatchedLenderProducts[] | null = null,
    matchedProductsFlat: Product[] | null = null,
    productsModelRequest: any,
  ): ((product: Product) => boolean) | null {
    if (!groupName) {
      return null;
    }

    if (!this.includeExcludeGroupsFilters.has(groupName)) {
      this.productsFilterHelper.resetSourcingFilters(matchedProductsFlat);
      return null;
    }

    const groupFilters = this.includeExcludeGroupsFilters.get(groupName);
    const includedFilterDefs = [...groupFilters.includedFilterDefs?.values()];
    const excludedFilterDefs = [...groupFilters.excludedFilterDefs?.values()];

    const hasIncluded = includedFilterDefs.length > 0;
    const hasExcluded = excludedFilterDefs.length > 0;

    if (!hasIncluded && !hasExcluded) {
      this.includeExcludeGroupsFilters.delete(groupName);
      this.productsFilterHelper.resetSourcingFilters(matchedProductsFlat);
      return null;
    }

    const includedFiltersNames = includedFilterDefs.map(f => f.title);

    const includeFilter = (product: Product) => {
      const includeFilterResult = includedFilterDefs.every(fd => {
        let isMatchedSubFilters = false;

        if (fd.hasSubFilters && fd.isMatchFuncFactory) {
          const checkedSubFilters = [...fd.checkedSubFilters];
          if (checkedSubFilters.length === 0) {
            isMatchedSubFilters = false;
          } else {
            const isMatch = checkedSubFilters
              .some(checkedFilterKey => fd.isMatchFuncFactory(checkedFilterKey)(product));
            isMatchedSubFilters = isMatch;
          }
        }

        const isUnknownMatched = !!fd.isUnknownFunc
          ? fd.isUnknownFunc(product, includedFiltersNames)
          : false;
        const isMatched = !!fd.isMatchFunc
          ? fd.isMatchFunc(product, matchedProducts, includedFiltersNames, productsModelRequest)
          : false;

        const result = includeUnknown
          ? isMatchedSubFilters || isMatched || isUnknownMatched
          : isMatchedSubFilters || isMatched;

        return result;
      });

      return includeFilterResult;
    };

    const excludeFilter = (product: Product) => {
      const excludeFilterResult = excludedFilterDefs.every(fd => {
        let isMatchedSubFilters = false;

        if (fd.hasSubFilters && fd.isMatchFuncFactory) {
          const checkedSubFilters = [...fd.checkedSubFilters];
          if (checkedSubFilters.length === 0) {
            isMatchedSubFilters = true;
          } else {
            const isMatch = checkedSubFilters
              .every(checkedFilterKey => !fd.isMatchFuncFactory(checkedFilterKey)(product));
            isMatchedSubFilters = isMatch;
          }
        }

        const isMatched = fd.isExcludeFunc
          ? fd.isExcludeFunc(product, includedFiltersNames)
          : !!fd.isMatchFunc
            ? !fd.isMatchFunc(product, matchedProducts, includedFiltersNames, productsModelRequest)
            : false;

        const isUnknownMatched = !!fd.isUnknownFunc
          ? fd.isUnknownFunc(product, includedFiltersNames)
          : false;
        const result = includeUnknown
          ? isMatchedSubFilters || isMatched || isUnknownMatched
          : isMatchedSubFilters || isMatched;

        return result;
      });

      return excludeFilterResult;
    };

    const groupFilter = (product: Product) => {
      if (hasIncluded && hasExcluded) {
        return includeFilter(product) && excludeFilter(product);
      }
      if (hasIncluded) {
        return includeFilter(product);
      }
      return excludeFilter(product);
    };

    return groupFilter;
  }

  public toggleAllSubFilters(groupKey: string, filter: ProductFilter, selectAll: boolean, bulkUpdate = false) {
    if (!this.includeExcludeGroupsFilters.has(groupKey)) {
      return;
    }

    this.isPristine = false;
    const groupFilter = this.includeExcludeGroupsFilters.get(groupKey);
    const filterDefs = [...groupFilter.includedFilterDefs?.values(), ...groupFilter.excludedFilterDefs?.values()];
    const filterDef = filterDefs.find(x => x.title === filter.title);
    if (!filterDef) {
      return;
    }

    filterDef.checkedSubFilters.clear();
    if (selectAll) {
      filter.subFilters?.items.forEach(i => filterDef.checkedSubFilters.add(i.title));
    }

    if (!bulkUpdate) {
      this.filterChanged.emit();
    }
  }

  public setSubFilter(groupKey: string, filterKey: string, subFilterKey: string, checked: boolean) {
    if (!this.includeExcludeGroupsFilters.has(groupKey)) {
      return;
    }

    this.isPristine = false;
    const groupFilter = this.includeExcludeGroupsFilters.get(groupKey);
    const filterDefs = [...groupFilter.includedFilterDefs?.values(), ...groupFilter.excludedFilterDefs?.values()];
    const filterDef = filterDefs.find(x => x.title === filterKey);
    if (!filterDef) {
      return;
    }

    if (checked) {
      filterDef.checkedSubFilters.add(subFilterKey);
    } else {
      filterDef.checkedSubFilters.delete(subFilterKey);
    }

    this.filterChanged.emit();
  }

  public mapCriteriaToFilters(criteriaName: string | undefined): string | null {
    switch (criteriaName) {
      case 'Expat not in UK':
        return 'Expat not in UK';
      case 'Help to Buy':
        return 'Help to Buy';
      case '2nd Residential':
        return 'Second Residential';
      case 'Limited Company Buy to Let':
        return 'Limited Company Buy to Let';
      case 'Let to Buy':
        return 'Let to Buy';
      case 'Portfolio Landlord':
        return 'Portfolio Landlord';
      case 'Regulated Buy to Let':
        return 'Regulated BTL';
      default:
        return null;
    }
  }

  public setFilter(groupKey: string, filterKey: string, checked: boolean, bulkUpdate = false) {
    if (checked && !this.checkedFilters.get(groupKey)?.has(filterKey)) {
      this.checkedFilters.get(groupKey)?.add(filterKey);
    } else if (!checked && this.checkedFilters.get(groupKey)?.has(filterKey)) {
      this.checkedFilters.get(groupKey)?.delete(filterKey);
    }

    if (!bulkUpdate) {
      this.filterChanged.emit();
    }
  }

  public setFilters(filters: { groupKey: string; filterKey: string; checked: boolean }[]) {
    filters.forEach(filter => {
      this.setFilter(filter.groupKey, filter.filterKey, filter.checked, true);
    });

    this.filterChanged.emit();
  }

  /**
   * Creates a function based on the currently selected filters that will apply said filters to a product.
   * Will return `null` if there are no filters to be applied to the product.
   */
  public getFilterFunc(
    context: FilterOptionsContext<Product | BridgingProduct>,
    includeUnknown = true,
    matchedProducts: MatchedLenderProducts[] | null = null,
    matchedProductsFlat: Product[] | null = null,
    productsModelRequest: any,
  ): ((product: Product) => boolean) | null {
    const appliedFilters = this.getActiveFilters(context, true)
      .filter(({ items, group }) => items.length > 0 && !group.isIncludeExcludeFilter)
      .map(({ group, items }) => {
        const filterFuncs = items.map(filterLabel => {
          if ('itemFactory' in group) {
            return group.isMatchFuncFactory(filterLabel);
          }
          const filterDef = group.items.find(x => x.title === filterLabel);
          return includeUnknown && filterDef?.isUnknownFunc
            ? (p: Product) => !!filterDef.isUnknownFunc?.(p) || !!filterDef.isMatchFunc?.(p)
            : filterDef?.isMatchFunc;
        });
        return (product: Product) => filterFuncs[group.itemOperator === 'and' ? 'every' : 'some'](f => f?.(product));
      });

    this.getIncludeExcludeFilterContexts()
      .map(r => r.group)
      .forEach(g => {
        const includeExcludeFilter = this.getIncludeExcludeFilter(
          g?.title,
          includeUnknown,
          matchedProducts,
          matchedProductsFlat,
          productsModelRequest,
        );
        if (includeExcludeFilter) {
          appliedFilters.push(includeExcludeFilter);
        }
      });

    this.getAllNumericValueFilters().forEach((numericFilter) => {
      appliedFilters.push(numericFilter);
    });

    this.getAllRangeFilters().forEach((rangeFilter) => {
      appliedFilters.push(rangeFilter);
    });

    return appliedFilters.length > 0
      ? (product: Product) => appliedFilters.every(f => f(product))
      : null;
  }

  public clearFilters(unsetProductFilters?: boolean, products?: Product[] | null) {
    this.isPristine = true;
    this.availableFiltersCache.clear();
    this.setProductFilterDefinitions();

    this.includeExcludeGroupsFilters?.clear();
    this.clearNumericValueFilters();
    this.clearRangeFilters();
    this.checkedFilters.forEach(grp => grp.clear());
    this.productsFilterHelper.resetSourcingFilters(products);
    this.filterChanged.emit(unsetProductFilters);
  }

  public clearSourcingFilters() {
    this.productsFilterHelper.clearSourcingFilters();
  }

  private clearNumericValueFilters() {
    this.numericValueFilters?.clear();
  }

  public clearRangeFilters() {
    this.rangeValueFilters?.clear();
  }

  private isFilterAvailable(
    groupDef: ProductFilterGroupDefinition<Product | BridgingProduct>,
    filterDef: ProductFilterDefinition<Product | BridgingProduct>,
    ctx: FilterOptionsContext<Product | BridgingProduct>,
  ) {
    if (!groupDef.isIncludeExcludeFilter) {
      return true;
    }

    if (!filterDef.isMatchFunc) {
      return true;
    }

    const productsCount = ctx?.products?.length;
    const filterCacheKey = `${filterDef.title}_${productsCount}`;
    if (this.availableFiltersCache.has(filterCacheKey)) {
      return this.availableFiltersCache.get(filterCacheKey);
    }

    // Display filter when context has any products related to that filter
    // (has any products match to isMatchFunc OR isExcludeFunc filters)
    const isFilterAvailable =
      !!filterDef.isUnknownFunc
        ? (ctx?.products?.some(p => !!filterDef.isMatchFunc ? filterDef.isMatchFunc(p) : true)
          || (!!filterDef.isExcludeFunc && ctx?.products?.some(filterDef.isExcludeFunc)))
          ?? ctx?.products?.some(filterDef.isUnknownFunc)
        : ctx?.products?.some(p => !!filterDef.isMatchFunc ? filterDef.isMatchFunc(p) : true)
          || (!!filterDef.isExcludeFunc && ctx?.products?.some(filterDef.isExcludeFunc));

    this.availableFiltersCache.set(filterCacheKey, isFilterAvailable);

    return isFilterAvailable;
  }

  public excludeTailoredProductsByDefault(products: Product[] | BridgingProduct[] | null | undefined) {
    if (this.isPristine && !!products?.length) {
      this.isPristine = false;

      const excludedGroupTitle = 'Tailored Products';
      const filterGroup = this.getFilterOptions({ products: products })?.find(g => g.title === excludedGroupTitle);
      const groupDef = this.productFilterDefinitions.find(g => g.title === excludedGroupTitle);
      if (groupDef) {
        const availableFilters = groupDef['items'].filter((filterDef) => {
          if (!!filterDef.visible) {
            return filterDef.visible(products) && this.isFilterAvailable(groupDef, filterDef, { products: products });
          }

          return this.isFilterAvailable(groupDef, filterDef, { products: products });
        });

        availableFilters.forEach(filterDef => {
          this.setIncludeExcludeFilter(excludedGroupTitle, filterDef.title, false, true, true);
          if (filterDef.hasSubFilters && filterGroup) {
            const filter = filterGroup?.items.find(f => f.title === filterDef.title);
            if (filter) {
              this.toggleAllSubFilters(excludedGroupTitle, filter, true, true);
            }
          }
        });
        this.filterChanged.emit();
        this.isPristine = true;
      }
    }
  }

  public get activeFilterTitles() {
    const groupFilters = [...this.includeExcludeGroupsFilters.values()];
    const includedFilterDefs = groupFilters.flatMap(gf => [...gf.includedFilterDefs?.values()]);
    return includedFilterDefs.map(f => f.title);
  }

  public mapFiltersToYears(text: string): string {
    const mapping: { [key: string]: string } = {
      '1 year': '12',
      '2 years': '24',
      '3 years': '36',
      '4 years': '48',
      '5 years': '60',
      '6 years': '72',
      '7 years': '84',
      '10 years': '120',
      '15 years': '180',
      'For Term': 'xx',
    };

    return mapping[text] || 'xx';
  }
}
