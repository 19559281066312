import {
  AffordabilityRequest,
  BdgAffordabilityRequest,
  BtlAffordabilityRequest,
  BtlType,
  LendingTypeCode,
  LoanSecurity,
  MethodOfRepayment,
  MortgageType,
  ProductLengthExtended,
  ProductType,
  ProductTypeString,
  PropertyType,
  PurchaserType,
  RemortgageType,
  RepaymentMethod,
  RepaymentVehicle,
  SearchType,
} from 'apps/shared/src/models';

export class ProductsModelRequest {
  public productsSearchId: string;
  public productType: LendingTypeCode;
  public productTypeExtended: ProductType;
  public newBuild?: boolean;
  public mortgageType: MortgageType;
  public propertyValue?: number;
  public loanAmount?: number;
  public outstandingBalanceCurrentMortgage?: number;
  public purchaserType?: PurchaserType;
  public repaymentMethod?: RepaymentMethod;
  public mortgageTermYears?: number;
  public mortgageTermMonths?: number;
  public interestOnlyAmount?: number;
  public productLengths?: ProductLengthExtended[];
  public rateTypes?: ProductTypeString[];
  public searchType: SearchType;
  public currentMortgageLender?: string;
  public productTransferInitialDate?: Date | string;
  public propertyType?: PropertyType | null;
  public productLengthFrom?: number;
  public productLengthTo?: number;
}

export class ProductsBdgModelRequest extends ProductsModelRequest {
  public affordabilitySearchId: string | null;
  public productType: LendingTypeCode;
  public regulated: boolean;
  public propertyValue?: number;
  public loanAmount?: number;
  public loanSecurity?: LoanSecurity;
  public currentMortgageBalanceOutstanding?: number;
  public methodOfRepayment?: MethodOfRepayment;
  public loanTerm?: number;
  public limitedCompany?: boolean;
  public repaymentMethod?: RepaymentMethod;
  public mortgageTermYears?: number;
  public productsSearchId: string;
  public searchType: SearchType;
  public mortgageType: MortgageType;
  public static mapAffordabilityToProductRequestBdg(request: BdgAffordabilityRequest, affordabilitySearchId: string) {
    return {
      productType: LendingTypeCode.Bdg,
      affordabilitySearchId: affordabilitySearchId,
      regulated: request.regulated,
      propertyValue: request.propertyValue,
      loanAmount: request.loanAmount,
      loanSecurity: request.loanSecurity,
      currentMortgageBalanceOutstanding: request.currentMortgageBalanceOutstanding,
      methodOfRepayment: request.methodOfRepayment,
      loanTerm: request.loanTerm,
      limitedCompany: request.limitedCompany,
      repaymentMethod: request.repaymentMethod,
      mortgageTermYears: request.mortgageTermYears,
      searchType: SearchType.Affordability,
      productsSearchId: affordabilitySearchId,
      mortgageType: MortgageType.Purchase,
    } as ProductsBdgModelRequest;
  }
}

export class ProductsResidentialModelRequest extends ProductsModelRequest {
  public remortgageType?: RemortgageType;
  public capitalRaising?: boolean;
  public helpToBuy?: boolean;
  public firstTimeBuyer?: boolean;
  public repaymentVehicle?: RepaymentVehicle;
  public equityValue: number;
  public purchasePriceShare?: number | null;
  public static mapAffordabilityToProductRequestResidential(
    request: AffordabilityRequest,
    affordabilitySearchId: string,
  ) {
    return {
      mortgageType: request.mortgageType,
      currentMortgageLender: request.currentMortgageLender,
      productTransferInitialDate: request.productTransferInitialDate,
      productType: LendingTypeCode.Res,
      productTypeExtended: request.productTypeExtended,
      newBuild: request.newBuild,
      propertyValue: request.propertyValue,
      loanAmount: request.loanAmount,
      productsSearchId: affordabilitySearchId,
      helpToBuy: request.helpToBuy,
      interestOnlyAmount: request.interestOnlyAmount,
      mortgageTermMonths: request.mortgageTermMonths,
      mortgageTermYears: request.mortgageTermYears,
      repaymentMethod: request.repaymentMethod,
      equityValue: request.equityValue,
      repaymentVehicle: request.repaymentVehicle,
      productLengths: [request.productLength as unknown as ProductLengthExtended],
      remortgageType: request.capitalRaising ? RemortgageType.CapitalRaising : null,
      searchType: SearchType.Affordability,
      currentMortgageBalance: request.amountTransferredFromOtherLender,
      propertyType: request.propertyType,
      firstTimeBuyer: request.purchaserType ?
        request.purchaserType === PurchaserType.FirstTimeBuyer : null,
    } as ProductsResidentialModelRequest;
  }
}

export class ProductsBtlModelRequest extends ProductsModelRequest {
  public remortgageType?: RemortgageType;
  public capitalRaising?: boolean;
  public btlType?: BtlType;
  public limitedCompanyPurchase?: boolean;
  public portfolioLandlord?: boolean;
  public firstTimeBuyer?: boolean;
  public static mapAffordabilityToProductRequestBtl(request: BtlAffordabilityRequest, affordabilitySearchId: string) {
    return {
      mortgageType: request.mortgageType,
      currentMortgageLender: request.currentMortgageLender,
      productTransferInitialDate: request.productTransferInitialDate,
      productType: LendingTypeCode.Btl,
      newBuild: request.newBuild,
      propertyValue: request.propertyValue,
      loanAmount: request.loanAmount,
      productsSearchId: affordabilitySearchId,
      interestOnlyAmount: request.interestOnlyAmount,
      mortgageTermMonths: request.mortgageTermMonths,
      mortgageTermYears: request.mortgageTermYears,
      repaymentMethod: request.repaymentMethod,
      btlType: request.btlType,
      limitedCompanyPurchase: request.limitedCompanyPurchase,
      portfolioLandlord: request.portfolioLandlord,
      remortgageType: request.remortgageType,
      propertyType: request.propertyType,
      firstTimeBuyer: request.firstTimeBuyer,
      productLengths: [request.productLength as unknown as ProductLengthExtended],
    } as ProductsBtlModelRequest;
  }
}

export interface ProductsBridgingModelRequest extends ProductsModelRequest {
  regulated: boolean;
  propertyValue?: number;
  loanAmount?: number;
  loanSecurity?: LoanSecurity;
  currentMortgageBalanceOutstanding?: number;
  methodOfRepayment?: MethodOfRepayment;
  loanTerm?: number;
  limitedCompany?: boolean;
}
