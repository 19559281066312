import { Component } from '@angular/core';
import { AuthorizeService, ModalStateService } from '@msslib/services';
import { S365Service } from 'apps/clubhub/src/app/ignite/services/s365.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-case-link-modal',
  templateUrl: 'case-link-modal.component.html',
  styleUrls: ['case-link-modal.component.scss'],
})
export class CaseLinkModalComponent {

  public constructor(
        private authService: AuthorizeService,
        private s365Service: S365Service,
        private modalState: ModalStateService,
  ) {}

  protected get clientId() {
    return this.authService.user?.client_id;
  }

  protected get s365Data() {
    return this.s365Service.s365Data;
  }

  protected async newSearch() {
    await firstValueFrom(this.s365Service.removeData());
    this.modalState.topmostModal?.dismiss();
    if (this.modalState.modalStack?.length > 0) {
      this.modalState.modal = this.modalState.topmostModal;
    }
  }

  protected keepDetails() {
    this.modalState.topmostModal?.close();
    if (this.modalState.modalStack?.length > 0) {
      this.modalState.modal = this.modalState.topmostModal;
    }
  }
}
