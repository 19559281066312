import { Injectable } from '@angular/core';
import { Userpilot } from 'userpilot';
import { AuthorizeService } from './authorize.service';
import { ConfigService } from './config.service';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root',
})
export class UserpilotService {
  private isUserSet = false;
  private getUserSubscription: Subscription;
  private trackPageReloadsSubscription: Subscription;

  public constructor(
    private authorizeService: AuthorizeService,
    private router: Router,
    private configService: ConfigService,
  ) { }

  public run() {
    const userPilotToken = this.getUserPilotToken();
    Userpilot.initialize(userPilotToken);

    this.getUserSubscription = this.identify().pipe(
      switchMap(() => this.trackPageReloads()),
    ).subscribe();
  }

  public stop() {
    this.isUserSet = false;
    this.trackPageReloadsSubscription?.unsubscribe();
    this.getUserSubscription?.unsubscribe();
  }

  private identify() {
    return this.authorizeService.getUser()
      .pipe(
        distinctUntilChanged((prev, current) => prev?.sub === current?.sub),
        tap((user) => {
          if (user) {
            // Set user id
            Userpilot.identify(user.sub);
            this.isUserSet = true;
          } else {
            this.isUserSet = false;
          }
        }),
      );
  }

  private trackPageReloads() {
    return this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => {
          this.reload();
        }),
      );
  }

  private reload() {
    if (this.isUserSet) {
      Userpilot.reload();
    }
  }

  private getUserPilotToken() {
    const userPilotToken = this.configService.userPilotToken;
    if (!userPilotToken) {
      throw new Error(
        '"UserPilot" token is undefined',
      );
    }
    return userPilotToken;
  }
}
