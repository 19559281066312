<div class="multiselect position-relative user-select-none" [class.multiselect-disabled]="disabled()">
  <div class="form-select" [class.is-open]="isOpen()" [ngClass]="'form-select-' + size()" (mousedown)="openButtonClick()" [attr.data-testid]="testId() ? testId() + '_toggle' : undefined" #reference>
    <div class="d-flex">
      @if (displayLabel().length) {
        <span class="selected-items-label">{{ displayLabel() }}&nbsp;</span>
      } @else {
        <span class="placeholder-label">{{ placeholder() }}&nbsp;</span>
      }
      @if (multi() && selectedValueArray()?.length > 0 && showItemCount()) {
        <span class="badge rounded-pill text-bg-2022--secondary align-self-center">{{ selectedItemCountLabel() }}</span>
      }
    </div>
  </div>

  <div class="dropdown" [@openClose]="isOpen() ? 'open' : 'closed'" #dropdown>
    @if (showSearch()) {
      <div class="search-pane">
        <div class="input-group input-group-sm">
          <span class="input-group-text"><i class="fas fa-search"></i></span>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="searchTerm"
            placeholder="Search..."
            [tabIndex]="isOpen() ? 0 : -1"
            (focus)="isSearchFocused.set(true)"
            (blur)="isSearchFocused.set(false); onBlur()"
            #searchInput
          />

          @if (searchTerm()?.length) {
            <button class="btn btn-outline-secondary border-start-0" type="button" tabindex="-1" (click)="clearSearchTerm()">
              <i class="fas fa-times-circle"></i>
            </button>
          }
        </div>
      </div>
    }

    <div [style.height]="optionsContainerHeight()">
      <cdk-virtual-scroll-viewport class="h-100" [itemSize]="optionHeight()" minBufferPx="400" maxBufferPx="400" tabindex="-1" #virtualScroller>
        <label
          *cdkVirtualFor="let option of visibleOptions(); let index = index; trackBy: trackByOptionValue"
          class="option"
          [class.is-hovered]="focusedOptionIndex() === index"
          [class.is-disabled]="disabled() || !getEnabled(option)"
          [class.is-parent]="isParentOption(option)"
          [class.is-child]="isChildOption(option)"
          [class.pointer-events-none]="isParentOption(option) && groupMode() === selectGroupMode.NoSelectParent"
          (mouseenter)="onOptionMouseEnter(index)"
          (mouseleave)="onOptionMouseLeave(index)"
          [attr.data-optionindex]="index"
          [style.height]="optionHeight() + 'px'"
        >
          <input
            [type]="multi() ? 'checkbox' : 'radio'"
            class="option-checkbox"
            [class.is-collapsed]="!showCheckboxes() || (groupMode() === selectGroupMode.NoSelectParent && isParentOption(option))"
            [checked]="isOptionSelected(option)"
            (change)="setOptionSelected(option, $event.target.checked); closeIfSingleSelect()"
            [disabled]="disabled() || !getEnabled(option)"
            tabindex="-1"
            [attr.data-testid]="testId() ? testId() + '_item' + getValue(option) : undefined"
          />

          <!-- For single-select, hide the radio button and show a checkmark icon instead -->
          @if (!multi() && showCheckboxes()) {
            <i class="input-icon fas fa-check me-2"></i>
          }

          @if (optionTemplate()) {
            <ng-container *ngTemplateOutlet="optionTemplate(); context: { $implicit: option, index: index, isFocused: focusedOptionIndex() === index, isSelected: isOptionSelected(option) }"></ng-container>
          } @else {
            <span class="option-label">{{getLabel(option)}}</span>
          }
        </label>
      </cdk-virtual-scroll-viewport>
    </div>

    @if (showLoadingSpinner()) {
      <div class="mx-auto mb-2 text-muted">
        <span class="spinner-border spinner-border-sm me-2" role="status"></span>
        <span>Loading {{pluralOptionNameActual()}}...</span>
      </div>
    } @else if (visibleOptions().length === 0) {
      <p class="text-center mb-2 text-muted">No {{pluralOptionNameActual()}} found...</p>
    }
  </div>
</div>
