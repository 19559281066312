import { Injectable } from '@angular/core';
import { LendingTypeService } from '../services';
import { AffordabilityRequest, ProductType, ProductsModelRequest } from 'apps/shared/src/models';

@Injectable({
  providedIn: 'root',
})
export class ProductLtvService {
  public constructor(
    private lendingTypeService: LendingTypeService,
  ) { }

  private get isResidentialType(): boolean {
    return this.lendingTypeService.isResLendingType;
  }

  public isSharedOwnershipRequest(request: ProductsModelRequest | AffordabilityRequest) {
    return this.isSharedOwnership(request.productTypeExtended);
  }

  public isSharedOwnership(productType?: ProductType) {
    if (!productType || !this.isResidentialType) {
      return false;
    }

    return productType === ProductType.SharedOwnership;
  }
}
